import React, { useState } from "react";
import { Menu, MenuItem, ClickAwayListener } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const TooltipMenu = ({ value, getColor, options, onChange, calculation }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const handleMenuItemClick = (option) => {
  //     console.log(`Clicked on ${option}`);
  //     handleClose();
  //   };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <span
          onClick={handleClick}
          style={{
            background: getColor(value),
            padding: "5px 10px",
            borderRadius: "10px",
            cursor: "pointer",
            minWidth: "30px",
          }}
        >
          {value}
          <ArrowDropDownIcon />
        </span>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              onClick={() => {
                onChange(option.value, calculation);
                handleClose();
              }}
              key={index}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

export default TooltipMenu;
