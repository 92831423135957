import React, { useEffect, useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import StackedChart from "../../components/stackedcChart";
import currency_symbols from "../../utils/currency.js";
import { Slider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import html2canvas from "html2canvas";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBar from "../../components/snackBar";

const useStyles = makeStyles({
  positive: {
    color: "#5a9dec",
    fontSize: 40,
    paddingTop: "25px",
  },
  negative: {
    color: "red",
    fontSize: 40,
    paddingTop: "25px",
  },
});

const InventoryComp = ({
  // handleAPSlider,
  chartAPDays,
  initChartAPDays,
  // handleARSlider,
  initChartARDays,
  chartARDays,
  chartData,
  // handleClick,
  currency,
  youHave,
  youNeed,
  shortage,
  isPositive,
  chartInventoryDays,
  selectData,
  initChartInventoryDays,
  // handleInventorySlider,
  // resetInvenCompValues,

  // pdfGraph,
  // setPdfGraph,

  // fmfInventoryGraphRef,
  // fmfInventoryWhatIfRef,
  dumInvVal,
  setDumInvVal,
  dumARVal,
  setDumARVal,
  dumAPVal,
  setDumAPVal,
}) => {
  // const fmfInventoryGraphRef = useRef(null);
  // const fmfInventoryWhatIfRef = useRef(null);

  const [PDFLoading, setPDFLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [snackType, setSnackType] = useState(false);

  // const fmfInventoryGraphRef = useRef(null);
  // const fmfInventoryWhatIfRef = useRef(null);

  // const [dumInvVal, setDumInvVal] = useState(chartInventoryDays);
  // const [dumARVal, setDumARVal] = useState(chartARDays);
  // const [dumAPVal, setDumAPVal] = useState(chartAPDays);

  const classes = useStyles();

  const apDaysMarks = [{ value: initChartAPDays, label: initChartAPDays }];
  const arDaysMarks = [{ value: initChartARDays, label: initChartARDays }];
  const inventoryDaysMarks = [
    { value: initChartInventoryDays, label: initChartInventoryDays },
  ];

  // useEffect(() => {
  //   // saveFmfInvPDF()
  //   setTimeout(() => saveFmfInvPDF(), 200);
  // }, [selectData, youNeed, shortage]);

  // const saveFmfInvPDF = async () => {
  //   // setPDFLoading(true);

  //   const graphCanvas = await html2canvas(fmfInventoryGraphRef.current);
  //   const base64ImageGraph = await graphCanvas.toDataURL("image/png");

  //   const whatIfCanvas = await html2canvas(fmfInventoryWhatIfRef.current);
  //   const base64ImageWhatIf = await whatIfCanvas.toDataURL("image/png");

  //   const updatedImages = {
  //     ...pdfGraph.images,
  //     fmfInvenGraph: base64ImageGraph,
  //     fmfInvenWhatIf: base64ImageWhatIf,
  //   };

  //   console.log("updatedImages", updatedImages);

  //   setPdfGraph({
  //     ...pdfGraph,
  //     images: updatedImages,
  //   });

  //   // setPDFLoading(false);
  //   // setOpenSnackBar(true);
  //   // setSnackType("success");
  //   // setMessage("Image saved");
  // };

  const handleUpdateInvSlider = (e) => {
    if (e.target.value < initChartInventoryDays / 8) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(
        `Value is too small! smallest value can be ${
          initChartInventoryDays / 8
        }`
      );
      return;
    }
    if (e.target.value > initChartInventoryDays * 2) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(
        `Value is too big! biggest value can be ${initChartInventoryDays * 2}`
      );
      return;
    }
    // handleInventorySlider(e);
  };

  const handleUpdateDateAccRevSlicer = (e) => {
    if (e.target.value < initChartARDays / 8) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(
        `Value is too small! samllest value can be ${initChartARDays / 8}`
      );
      return;
    }
    if (e.target.value > initChartARDays * 2) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(
        `Value is too big! biggest value can be ${initChartARDays * 2}`
      );
      return;
    }
    // handleARSlider(e);
  };

  const handleUpdateAccPaySlider = (e) => {
    if (e.target.value < chartAPDays / 8) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(
        `Value is too small! smallest value can be ${chartAPDays / 8}`
      );
      return;
    }
    if (e.target.value > chartAPDays * 2) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(`Value is too big! biggest value can be ${chartAPDays * 2}`);
      return;
    }
    // handleAPSlider(e);
  };

  return (
    <>
      <StackedChart
        // pdfGraph={pdfGraph}
        // setPdfGraph={setPdfGraph}
        chartData={chartData}
        // handleClick={handleClick}
      />
      <div className="centerDiv">
        <div>
          <p style={{ paddingRight: "20px", paddingBottom: "20px" }}>
            Money In
          </p>
          <p>Money out</p>
        </div>
        <div>
          <div
            style={{ marginRight: "20px", marginBottom: "20px" }}
            className="rectangleID"
          >
            Inventory Days : {chartData[0].x}
          </div>
          <div className="rectangleAP">AP Days : {chartData[1].x}</div>
        </div>
        <div>
          <div
            style={{ marginRight: "20px", marginBottom: "20px" }}
            className="rectangleAR"
          >
            AR Days : {chartData[2].x}
          </div>
          <div className="rectangleFG">
            The Financial Gap : {chartData[3].x}
          </div>
        </div>
      </div>
    </>
  );
};
export default InventoryComp;
