import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function CurrentRatioChart({ currentRatio, dates }) {
  // { trends, range }
  const options = {
    responsive: true,
    display: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
    },
  };

  //   const labels = range;
  const labels = dates;

  const data = {
    labels,
    datasets: [
      {
        label: "Current Ratio",
        data: currentRatio,
        borderColor: "#4caf50",
        backgroundColor: "#80ca83",
      },
    ],
  };

  return (
    <div>
      <Line options={options} data={data} />
    </div>
  );
}
