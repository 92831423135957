import React, { useState, useEffect } from "react";
import { Typography, Box, Container, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/index.js";
import SnackBar from "../../components/snackBar";
import Logo from "../../images/logo2.png";
import whiteLogo from "../../images/logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "../../redux/actions/user.js";
import { updatePassword } from "../../redux/actions/user.js";
import { clearSnack } from "../../utils/utilFunctions.js";

export default function NewPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [cnfrmVisibility, setCnfrmVisibility] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [userId, setUserId] = useState();

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const { userLoading } = useSelector((state) => state.userReducer);

  const validateToken = async () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const secretKey = url.searchParams.get("secretKey");

    const resp = await dispatch(
      verifyToken({
        secretKey,
      })
    );

    setLoading(false);
    if (resp.isValid) {
      setUserId(resp.userId);
      setIsValid(true);
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "success",
          message: "Please enter new password",
        },
      });
      return;
    }
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  useEffect(() => {
    validateToken();
  }, []);

  const handleReset = () => {
    if (!newPassword || !confirmPassword) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "All fields are required",
        },
      });
      clearSnack(dispatch);
      return;
    }
    if (newPassword !== confirmPassword) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Password not matched!",
        },
      });
      clearSnack(dispatch);
      return;
    }

    const data = {
      userId,
      password: newPassword,
    };
    dispatch(updatePassword(data, navigate));
  };

  return (
    <React.Fragment>
      <Header page="login" />
      <div className="main_bg">
        <Container>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "space-between",
                marginTop: "200px",
              }}
            >
              <img src={whiteLogo} alt="logo" width="90%" height="90px" />
            </Grid>
            <Grid item xs={12} lg={5} mt={4} mb={3}>
              <Box className="main_div3" sx={{ padding: "40px 50px" }}>
                <Box className="login-header">
                  <Typography
                    className="trans_typo1"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Reset Password
                  </Typography>
                </Box>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    style={
                      {
                        //   height: "20px",
                        //   width: "20px",
                        //   marginLeft: "5%",
                      }
                    }
                  />
                ) : (
                  <>
                    <Typography className="main_typo3" sx={{ mt: 2 }}>
                      New Password
                    </Typography>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <input
                        className="customize-input1"
                        placeholder="Enter password"
                        color="white"
                        name="password"
                        type={visibility ? "text" : "password"}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      {!visibility ? (
                        <VisibilityIcon
                          onClick={() => setVisibility(true)}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "40%",
                            color: "#03111e",
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => setVisibility(false)}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "40%",
                            color: "#03111e",
                          }}
                        />
                      )}
                    </div>

                    <Typography className="main_typo3" sx={{ mt: 2 }}>
                      Confirm Password
                    </Typography>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <input
                        className="customize-input1"
                        placeholder="Enter password"
                        color="white"
                        name="password"
                        type={cnfrmVisibility ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      {!cnfrmVisibility ? (
                        <VisibilityIcon
                          onClick={() => setCnfrmVisibility(true)}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "40%",
                            color: "#03111e",
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => setCnfrmVisibility(false)}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "40%",
                            color: "#03111e",
                          }}
                        />
                      )}
                    </div>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        className="login-btn"
                        disabled={userLoading}
                        onClick={handleReset}
                      >
                        Reset
                        {userLoading ? (
                          <CircularProgress
                            color="inherit"
                            style={{
                              height: "20px",
                              width: "20px",
                              marginLeft: "5%",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Button>
                    </Box>
                  </>
                )}
                <a href="https://cashflowmike.com/" target="blank">
                  {" "}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img src={Logo} alt="" width="40%" />
                  </Box>
                </a>
              </Box>
            </Grid>
          </Grid>
          <SnackBar open={openSnackbar} snackType={snackType2} msg={message2} />
        </Container>
      </div>
    </React.Fragment>
  );
}
