// store.js

import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers"; // You need to create your reducers
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "main-root",
  storage,
};

const middleware = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer, // Combine your reducers using combineReducers if needed
  applyMiddleware(...middleware)
);

const Persistor = persistStore(store);

export { Persistor };
export default store;
