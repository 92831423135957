import React, { useState } from "react";
import { Typography, Box, Container, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import SnackBar from "../../components/snackBar";
import { BASE_URL } from "../../utils/config.js";
import Logo from "../../images/logo2.png";
import whiteLogo from "../../images/logo-white.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Header } from "../../components/index.js";

export default function Signup() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [snackType, setSnackType] = useState(false);
  const [message, setMessage] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const getQueryParam = (paramName) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(paramName);
  };

  const validationSchema = yup.object({
    name: yup.string().required("Enter your name"),
    email: yup.string().email().required("Enter your email"),
    password: yup
      .string()
      .min(8, "Minimun 8 Characters required")
      .required("Enter your password"),
  });

  const isMemberEmail = getQueryParam("email");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: isMemberEmail ? isMemberEmail : "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      setLoading(true);

      axios
        .post(`${BASE_URL}/addUser`, values)
        .then((res) => {
          setLoading(false);
          if (res) {
            setOpen(true);
            setSnackType("success");
            setMessage("Registered successfully");

            localStorage.setItem("userEmail", values.email);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("id", res.data.user._id);
            localStorage.setItem("paymentStatus", res.data.user.payment_status);
            localStorage.setItem(
              "cpcp_subscribed",
              res.data.user.cpcp_subscribed
            );

            setTimeout(() => {
              if (
                res.data.user.payment_status ||
                res.data.user.cpcp_subscribed
              ) {
                navigate("/home");
              } else {
                navigate("/select");
              }
            }, 2000);
          }
        })
        .catch((error) => {
          setLoading(false);
          setOpen(true);
          setMessage(error?.response?.data?.message);
          setSnackType("");
        });
    },
  });

  return (
    <React.Fragment>
      <Header page="signup" />

      <div className="main_bg">
        <Container>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "space-between",
                marginTop: "250px",
              }}
            >
              {/* <h1 className="login-head-text">
              Clear{" "}
              <span style={{ color: "#00c4f4", fontSize: "70px" }}> Path</span>{" "}
              to Cash
            </h1> */}
              <img src={whiteLogo} alt="logo" width="90%" height="90px" />

              {/* <p className="login-head-des">
              More Money, More Time, Better Business
            </p> */}
              {/* <p style={{ color: "white" }}>
              Privacy | T&C. Cash Flow Mike | All Rights Reserved
            </p> */}
            </Grid>
            <Grid item xs={12} lg={5} mt={4} mb={3}>
              <form onSubmit={formik.handleSubmit} className="login-bg">
                <Box className="main_div3" sx={{ padding: "30px 50px" }}>
                  <Typography
                    className="trans_typo1"
                    style={{ textAlign: "center" }}
                  >
                    Signup
                  </Typography>
                  <Typography className="main_typo3" sx={{ mt: 1 }}>
                    Name
                  </Typography>
                  <input
                    className="customize-input1"
                    placeholder="Enter Name"
                    color="white"
                    name="name"
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  <small className="red">
                    {formik.touched.name && formik.errors.name}
                  </small>

                  <Typography className="main_typo3" sx={{ mt: 1 }}>
                    Email
                  </Typography>
                  <input
                    className="customize-input1"
                    placeholder="Enter Email"
                    color="white"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <small className="red">
                    {formik.touched.email && formik.errors.email}
                  </small>
                  <Typography className="main_typo3" sx={{ mt: 1 }}>
                    Password
                  </Typography>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <input
                      className="customize-input1"
                      placeholder="Enter Password"
                      color="white"
                      name="password"
                      type={visibility ? "text" : "password"}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    {!visibility ? (
                      <VisibilityIcon
                        onClick={() => setVisibility(true)}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "40%",
                          color: "black",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => setVisibility(false)}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "40%",
                          color: "black",
                        }}
                      />
                    )}
                  </div>
                  <small className="red">
                    {formik.touched.password && formik.errors.password}
                  </small>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className="login-btn"
                      type="submit"
                      disabled={loading}
                    >
                      Signup
                      {loading ? (
                        <CircularProgress
                          color="inherit"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "5%",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Button>
                  </Box>
                  <Typography
                    sx={{
                      color: "black",
                      textAlign: "center",
                      fontSize: "14px",
                      mt: 3,
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      to={"/"}
                      style={{
                        cursor: "pointer",
                        fontWeight: "600",
                        color: "#1976d2",
                      }}
                    >
                      Login
                    </Link>
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img src={Logo} alt="" width="40%" />
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
          <SnackBar
            setOpen={setOpen}
            open={open}
            snackType={snackType ? "success" : "error"}
            msg={message}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}
