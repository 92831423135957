import React from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  //   integerLimit: 7, // limit length of integer numbers
  allowNegative: true,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  console.log("currencyMask", currencyMask);

  return (
    <MaskedInput
      mask={currencyMask}
      {...inputProps}
      style={{ backgroundColor: "red" }}
    />
  );
};

CurrencyInput.defaultProps = {
  inputMode: "numeric",
  maskOptions: {},
};

export default CurrencyInput;
