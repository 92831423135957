import {
  Home,
  HomeRun,
  HiddenCash,
  FinancialData,
  CompanyDetail,
  SimpleValuation,
  FastMoneyFormula,
  Login,
  SubSelect,
  Subscription,
  Signup,
  PDF,
  ResetPassword,
  NewPassword,
  ContactUs,
  FinancialDoctor,
  StartWithEndInMind,
} from "../pages";

export const ROUTES = [
  {
    path: "/",
    element: Login,
  },
  {
    path: "/new-password",
    element: NewPassword,
  },
  {
    path: "/signup",
    element: Signup,
  },
  {
    path: "/reset-password",
    element: ResetPassword,
  },
  {
    path: "/home",
    element: Home,
  },
  {
    path: "/home-run",
    element: HomeRun,
  },
  {
    path: "/hidden-cash",
    element: HiddenCash,
  },
  {
    path: "/financial-doctor",
    element: FinancialDoctor,
  },
  {
    path: "/financial-data",
    element: FinancialData,
  },
  {
    path: "/fast-money-formula",
    element: FastMoneyFormula,
  },
  {
    path: "/simple-valuation",
    element: SimpleValuation,
  },
  {
    path: "/company-detail",
    element: CompanyDetail,
  },
  {
    path: "/subscription",
    element: Subscription,
  },
  {
    path: "/select",
    element: SubSelect,
  },
  {
    path: "/pdf",
    element: PDF,
  },
  {
    path: "/contact-us",
    element: ContactUs,
  },
  {
    path: "/start-with-end-in-mind",
    element: StartWithEndInMind,
  },
];
