import React from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "",
  suffix: "",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  allowNegative: true,
  allowLeadingZeroes: false,
};

const NumberInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return (
    <MaskedInput
      mask={currencyMask}
      {...inputProps}
      style={{ backgroundColor: "red" }}
    />
  );
};

NumberInput.defaultProps = {
  inputMode: "numeric",
  maskOptions: {},
};

export default NumberInput;
