import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import StackedChartService from "../../components/stackedChartService";
import currency_symbols from "../../utils/currency.js";
import { Slider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Tooltip from "@material-ui/core/Tooltip";
import html2canvas from "html2canvas";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBar from "../../components/snackBar";

const useStyles = makeStyles({
  positive: {
    color: "#5a9dec",
    fontSize: 40,
    paddingTop: "25px",
  },
  negative: {
    color: "red",
    fontSize: 40,
    paddingTop: "25px",
  },
});

const ServiceComp = ({
  initChartARDays,
  selectData,
  chartARDays,
  chartData,
  currency,
  youHave,
  youNeed,
  shortage,
  isPositive,
  avgPayrollExpense,
  initAvgPayrollExpense,
  daysInPayroll,
}) => {
  const [PDFLoading, setPDFLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [snackType, setSnackType] = useState(false);

  const [dumAPVal, setDumAPVal] = useState(chartARDays);
  const [dumAvgVal, setDumAvgVal] = useState(avgPayrollExpense);

  const avgPayrollMarks = [
    {
      value: initAvgPayrollExpense,
      label: `$${initAvgPayrollExpense?.toLocaleString()}`,
    },
  ];

  const initChartARDaysMarks = [
    { value: initChartARDays, label: initChartARDays },
  ];

  const classes = useStyles();

  const handleUpdateAvgSlider = (e) => {
    if (e.target.value < initAvgPayrollExpense / 8) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(
        `Value is too small! smallest value can be ${initAvgPayrollExpense / 8}`
      );
      return;
    }
    if (e.target.value > initAvgPayrollExpense * 2) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(
        `Value is too big! biggest value can be ${initAvgPayrollExpense * 2}`
      );
      return;
    }
  };

  const handleUpdateAPSlider = (e) => {
    if (e.target.value < initChartARDays / 8) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(
        `Value is too small! smallest value can be ${initChartARDays / 8}`
      );
      return;
    }
    if (e.target.value > initChartARDays * 2) {
      setOpenSnackBar(true);
      setSnackType("");
      setMessage(
        `Value is too big! biggest value can be ${initChartARDays * 2}`
      );
      return;
    }
  };

  return (
    <StackedChartService
      daysInPayroll={parseInt(daysInPayroll)}
      chartData={chartData}
      chartARDays={chartARDays}
    />
  );
};

export default ServiceComp;
