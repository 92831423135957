import React from "react";
import { Grid } from "@mui/material";
import "./style.css";

const CardWrapper = ({ children, border }) => {
  return (
    <React.Fragment>
      <Grid
        container
        xs={12}
        className="card_wrapper_main"
        style={{ border: border && border }}
      >
        {children}
      </Grid>
    </React.Fragment>
  );
};
export default CardWrapper;
