import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import store, { Persistor } from "./redux/store";
import { ROUTES } from "./routes/routes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

const App = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={Persistor}>
          <BrowserRouter>
            <Routes>
              {ROUTES?.map((route, index) => (
                <Route
                  key={index}
                  path={route?.path}
                  element={<route.element />}
                />
              ))}
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.Fragment>
  );
};
export default App;
