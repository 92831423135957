const initialState = {
  openSnackbar: false,
  snackType: "",
  message: "",
};

const snackbarReducers = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_SNACK":
      return {
        ...state,
        openSnackbar: true,
        snackType: action.payload.snackType,
        message: action.payload.message,
      };

    case "CLEAR":
      return {
        ...state,
        ...initialState,
      };

    case "INIT_STATES":
      return { ...state, ...initialState };

    default:
      return state;
  }
};

export default snackbarReducers;
