import React, { useEffect, useState, useRef } from "react";
import { Layout } from "../../layout";
import { CardWrapper, Accordian } from "../../components";
import "./style.css";
import {
  Typography,
  Box,
  Grid,
  Button,
  MenuItem,
  Select,
  CircularProgress,
  Modal,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  fetchMilestones,
  updateMilestones,
  addMilestones,
  deleteMileStone,
  updateMilestoneData,
} from "../../redux/actions/simpleValuation";
import { useDispatch, useSelector } from "react-redux";
import MilestonesLineGraph from "../../components/milestonesGraph";
import AddMilestoneModal from "./milestoneModal";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "../../components/CurrencyInput";
import { industryVals } from "../../utils/industries";
import { fetchSimpleValNotes } from "../../redux/actions/notes";
import SnackBar from "../../components/snackBar";
import html2canvas from "html2canvas";
import { updatePDFData } from "../../redux/actions/pdfGraph";
import { handlInfo } from "../../utils/utilFunctions";
import TrashIcon from "../../assets/trash-icon.svg";
import EditIcon from "../../assets/edit-icon.svg";
import { clearSnack } from "../../utils/utilFunctions";
import { OPEN_SNACK } from "../financial-data";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#efefef",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function sortBySeqNumber(data) {
  if (Array.isArray(data)) {
    // Sort the array based on the "seqnumber" property
    data.sort((a, b) => {
      const seqA = parseInt(a.seqnumber, 10) || 0;
      const seqB = parseInt(b.seqnumber, 10) || 0;
      return seqA - seqB;
    });
    return data;
  } else {
    console.log("Invalid input. The input should be an array.");
  }
}

const SimpleValuation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const [mileStoneId, setMileStoneId] = useState("");

  const simpleValGraphRef = useRef(null);
  const simpleValNotesRef = useRef(null);

  const simpleValMilestonesRef = useRef(null);

  const { openSnackbar, snackType, message } = useSelector(
    (state) => state.snackbarReducer
  );

  const [simpleValData, setSimpleValData] = useState();
  const [simpleValDataSeqSort, setSimpleValDataSeqSort] = useState();
  const [graphDate, setGraphData] = useState({});
  const [dummySimpleVal, setDummySimpleVal] = useState(simpleValData);
  const [modalOpen, setModalOpen] = useState(false);

  const [simpleValLoading, setSimpleValLoading] = useState(false);

  const [isMSEdit, setIsMSEdit] = useState(false);

  const [filteredData, setFilteredData] = useState("");
  const [ebidta, setEbidta] = useState("");
  const [ebidtaRange, setEbidtaRange] = useState("");
  const [capx, setCapx] = useState("0");
  const [familyExcess, setFamilyExcess] = useState("0");
  const [freeCashFlow, setfreeCashFlow] = useState("");
  const [multipleVal, setMultipleVal] = useState("");
  const [initialMultipleVal, setInitialMultipleVal] = useState("");
  const [marketVal, setMarketVal] = useState("");
  const [longTermDebt, setLongTermDebt] = useState("");
  const [totalEquity, setTotalEquity] = useState("");
  const [equityValue, setEquityValue] = useState("");
  const [eaDate, seteaDate] = useState();
  const [eaPeriodId, seteaPeriodId] = useState();
  const [isError, setError] = useState("");
  const [selectedIndus, setSelectedIndus] = useState("");
  const [isGraphUpdate, setIsGraphUpdate] = useState("");

  const [earningApproachPeriodId, setEarningApproachPeriodId] = useState();
  const [earningApproachPeriod, setEarningApproachPeriod] = useState();

  const InitMilestoneData = {
    name: "",
    date: "",
    // periodId: "",
    // periodName: "",
  };

  const [milestoneData, setMilestoneData] = useState(InitMilestoneData);

  const { periods, financialData, company } = useSelector(
    (state) => state.companyDetailReducer
  );

  const { simpleValNotes } = useSelector((state) => state.notesReducer);

  const savePDFDataSimpleVal = () => {
    dispatch({
      type: "GRAPH_LOADING",
    });
    setTimeout(() => saveSimpleValPDF(), 2000);
  };

  useEffect(() => {
    savePDFDataSimpleVal();
  }, [isGraphUpdate]);

  const saveSimpleValPDF = async () => {
    try {
      if (!simpleValGraphRef.current || !simpleValNotesRef.current) {
        dispatch({
          type: "STOP_GRAPH_LOADING",
        });
        return;
      }

      const simpleValGraphCanvas = await html2canvas(simpleValGraphRef.current);
      const base64ImagesimpleValGraph = await simpleValGraphCanvas.toDataURL(
        "image/png"
      );

      const simpleValMilestonesGraphCanvas = await html2canvas(
        simpleValMilestonesRef.current
      );
      const base64ImagesimpleValMilestonesGraphCanvas =
        await simpleValMilestonesGraphCanvas.toDataURL("image/png");

      const simpleValNotesCanvas = await html2canvas(simpleValNotesRef.current);
      const base64ImagesimpleValNotesCanvas =
        await simpleValNotesCanvas.toDataURL("image/png");

      const payload = {
        images: {
          simpleVal: base64ImagesimpleValGraph,
          simpleValMilestone: base64ImagesimpleValMilestonesGraphCanvas,
        },
        notes: {
          simpleVal: base64ImagesimpleValNotesCanvas,
        },
      };

      dispatch(updatePDFData(payload));

      dispatch({
        type: "STOP_GRAPH_LOADING",
      });
    } catch (error) {
      console.log("error inside pdf", error);
    }
  };

  const fetchPoints = async () => {
    try {
      const response = await dispatch(fetchMilestones(company._id));

      const simpleValCopy = [...response.data.simpleVals];

      setSimpleValDataSeqSort(sortBySeqNumber(simpleValCopy));

      setSimpleValData(response.data.simpleVals);
      setGraphData({
        datesArray: response.data.datesArray,
        pointsArray: response.data.pointsArray,
      });
      setSimpleValLoading(false);
    } catch (error) {
      setSimpleValLoading(false);
    }
  };

  useEffect(() => {
    try {
      setSimpleValLoading(true);
      if (Object.keys(company).length) {
        setSelectedIndus(company.industry);

        if (!company.industry) {
          dispatch({
            type: "OPEN_SNACK",
            payload: {
              snackType: "error",
              message: "Please edit company and select industry first",
            },
          });
          clearSnack(dispatch);

          setTimeout(() => {
            setSimpleValLoading(false);
            navigate("/home");
          }, 3000);

          return;
        }

        setEbidtaRange(company.ebidtaRange);
        fetchPoints();
        const payload = {
          companyId: company._id,
          page: "SimpleVal",
        };
        dispatch(fetchSimpleValNotes(payload));
      }
      setSimpleValLoading(true);
    } catch (error) {
      console.log("ererewrescvdfg", error);
    }
  }, [company]);

  const handleMilestoneClose = () => setModalOpen(false);

  const handleSaveMilestone = async () => {
    try {
      // const { periodId, date, name } = milestoneData;
      const { date, name } = milestoneData;

      const currentDate = new Date(date);

      const options = { year: "numeric", month: "long", day: "numeric" };

      const newDate = currentDate.toLocaleDateString("en-US", options);

      if (!date || !name) {
        dispatch({
          type: "OPEN_SNACK",
          payload: {
            snackType: "error",
            message: "Please fill all data",
          },
        });
        clearSnack(dispatch);
        return;
      }

      const data = {
        ...milestoneData,
        date: newDate,
        companyId: company._id,
      };

      if (isMSEdit) {
        await dispatch(updateMilestoneData(data, mileStoneId));
      } else {
        await dispatch(addMilestones(data));
      }

      await fetchPoints();
      setModalOpen(false);
    } catch (error) {
      console.log("errorr", error);
    }
  };

  const emptyInputsPeriod = () => {
    setCapx("");
    setFamilyExcess("");
    setMultipleVal("");
    setMarketVal("");
    setEquityValue("");
  };

  const handleSelectedMarkerVals = (marker) => {
    // if (!marker.markerPeriodId) {
    if (!marker.marketVal) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Marker is not connected",
        },
      });
      clearSnack(dispatch);
      return;
    }

    const currentPeriodId = marker.eaPeriodId
      ? marker.eaPeriodId
      : marker.markerPeriodId;

    const data = simpleValData.find(
      (s) => s.eaPeriodId === currentPeriodId
      // (s) => s.eaPeriodId === marker.markerPeriodId
    );

    const value = financialData.find((x) => x._id === currentPeriodId); //selected year data

    let initMarketVal = industryVals[data?.industry][data?.ebidtaRange];

    setCapx(data.balanceSheet?.capx || data.capx);
    setFamilyExcess(data.incomeStatement?.familyExcess || data.familyExcess);
    setMultipleVal(data.multipleVal);
    setInitialMultipleVal(initMarketVal);
    console.log("data.marketVal", data.marketVal);
    setMarketVal(data.marketVal);
    setEbidta(parseInt(data.ebidta));
    setLongTermDebt(value.balanceSheet.longTermDebt);
    setTotalEquity(value.balanceSheet.totalEquity);
    setEquityValue(data.marketVal - value.balanceSheet.longTermDebt);
    seteaDate(marker.date);
    setFilteredData(data.eaPeriodId);
    seteaPeriodId(data.eaPeriodId);
  };

  const handleChange = (event) => {
    try {
      const value = financialData.find((x) => x._id === event.target.value); //selected year data

      console.log(" event.target.value", event.target.value);
      console.log(simpleValData);

      const selectedSimpleVal = simpleValData.find(
        (x) => x.eaPeriodId === event.target.value
      );

      if (selectedSimpleVal) {
        const ebidtaVal =
          parseInt(value.incomeStatement.netProfiteBeforeTaxinUSD) +
          parseInt(value.incomeStatement.interestExpense) +
          parseInt(value.incomeStatement.taxesPaid) +
          parseInt(value.incomeStatement.depExpense) +
          parseInt(value.incomeStatement.amortExpense);

        if (ebidtaVal <= 0) {
          dispatch({
            type: "OPEN_SNACK",
            payload: {
              snackType: "error",
              message: "Required Data Is Needed For This Period",
            },
          });
          clearSnack(dispatch);
          return;
        }

        setFilteredData(event.target.value); //date

        let dumEbidtaRange = "";

        if (ebidtaVal < 999000) {
          dumEbidtaRange = "0k-99k";
        } else if (ebidtaVal > 1000000 && ebidtaVal < 4990000) {
          dumEbidtaRange = "1M-4.99M";
        } else if (ebidtaVal > 5000000 && ebidtaVal < 9990000) {
          dumEbidtaRange = "5M-9.99M";
        } else if (ebidtaVal > 10000000 && ebidtaVal < 24990000) {
          dumEbidtaRange = "10M-24.99M";
        } else if (ebidtaVal > 25000000 && ebidtaVal < 49990000) {
          dumEbidtaRange = "25M-49.99M";
        } else if (ebidtaVal > 50000000) {
          dumEbidtaRange = "50M+";
        }

        setEbidtaRange(dumEbidtaRange);

        setEbidta(parseInt(ebidtaVal));

        setLongTermDebt(value.balanceSheet.longTermDebt);

        emptyInputsPeriod();

        seteaPeriodId(value._id);

        setEarningApproachPeriod(value);
        seteaDate(value.year);

        let dumyCapX = selectedSimpleVal?.capx || "0";
        let dumyFamExcess = selectedSimpleVal?.familyExcess || "0";

        // if (dumyCapX && dumyFamExcess) {
        setCapx(dumyCapX);
        setFamilyExcess(dumyFamExcess);

        console.log("ebidtaVal", ebidtaVal);
        console.log("dumyCapX", dumyCapX);
        console.log("dumyFamExcess", dumyFamExcess);

        let frecash =
          parseInt(ebidtaVal) - parseInt(dumyCapX) + parseInt(dumyFamExcess);
        setfreeCashFlow(frecash);

        console.log("selectedSimpleVal", selectedSimpleVal);
        console.log(
          "selectedSimpleVal?.multipleVal",
          selectedSimpleVal?.multipleVal
        );
        console.log("selectedIndus", selectedIndus);
        console.log("dumEbidtaRange", dumEbidtaRange);

        setInitialMultipleVal(industryVals[selectedIndus][dumEbidtaRange]);

        const multiple =
          selectedSimpleVal?.multipleVal ||
          industryVals[selectedIndus][dumEbidtaRange];

        setMultipleVal(multiple);
        const marVal = multiple * frecash;

        console.log("frecash", frecash);
        console.log("multiple", multiple);
        console.log("marVal", marVal);

        setMarketVal(marVal);
        const eqVal = marVal - value.balanceSheet.longTermDebt;
        setEquityValue(eqVal);
        // }
        // setEarningApproachPeriodId(value._id);
      } else {
        dispatch({
          type: "OPEN_SNACK",
          payload: {
            snackType: "error",
            message:
              "Please add the required values for this period in Financial Data.",
          },
        });
        clearSnack(dispatch);
        return;
      }
    } catch (error) {
      console.log("error inside handleChange", error);
    }
  };

  const handleMulipleValChange = (e) => {
    const multpleVal = e.target.value;

    if (!multpleVal) return;

    const freecash = ebidta - parseInt(capx) + parseInt(familyExcess);

    setfreeCashFlow(freecash);
    setMultipleVal(multpleVal);

    const marVal = multpleVal * freecash;

    setMarketVal(marVal);

    const eqVal = marVal - longTermDebt;

    setEquityValue(eqVal);
  };

  const handleInputCapX = (e) => {
    const val = e.target.value;
    handleSetCapX(val);
  };

  const handleSetCapX = (val) => {
    // if (!isIndustrySelected()) return;
    setCapx(val);

    const vall =
      typeof val === "string" ? parseInt(val.replaceAll(",", "")) : val;

    if (familyExcess && val !== "") {
      const familyExcessDum =
        typeof familyExcess === "string"
          ? parseInt(familyExcess.replaceAll(",", ""))
          : familyExcess;

      let frecash = ebidta - vall + familyExcessDum;
      setfreeCashFlow(frecash);
      const multiple = multipleVal
        ? multipleVal
        : industryVals[selectedIndus][ebidtaRange];
      setMultipleVal(multiple);
      const marVal = multiple * frecash;
      setMarketVal(marVal);
      const eqVal = marVal - longTermDebt;
      setEquityValue(eqVal);
    }
  };

  const handleInputFamEx = (e) => {
    const val = e.target.value;
    handleFamExChange(val);
  };

  const handleFamExChange = (val) => {
    // if (!isIndustrySelected()) return;

    if (!val) return;

    if (!filteredData) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Please select period",
        },
      });
      clearSnack(dispatch);
      return;
    }

    if (!ebidta) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Required values to calculate EBITDA",
        },
      });
      clearSnack(dispatch);
      return;
    }

    let ebidtaDum =
      typeof ebidta === "string"
        ? parseInt(ebidta.replaceAll(",", ""))
        : ebidta;

    let capxDum =
      typeof capx === "string" ? parseInt(capx.replaceAll(",", "")) : capx;

    let eVal =
      typeof val === "string" ? parseInt(val.replaceAll(",", "")) : val;

    setFamilyExcess(val);

    let frecash = ebidtaDum - capxDum + eVal;

    setfreeCashFlow(frecash);

    const multiple = multipleVal
      ? multipleVal
      : industryVals[selectedIndus][ebidtaRange];

    setMultipleVal(multiple);
    const marVal = multiple * frecash;

    setMarketVal(marVal);
    const eqVal = marVal - longTermDebt;

    setEquityValue(eqVal);
  };

  const handleSavePoint = async () => {
    try {
      if (!marketVal) {
        dispatch({
          type: "OPEN_SNACK",
          payload: {
            snackType: "error",
            message: "Please add the values",
          },
        });
        clearSnack(dispatch);
        return;
      }

      const payload = {
        marketVal,
        eaPeriodId: eaPeriodId,
        companyId: company._id,
        capx,
        familyExcess,
        ebidtaRange,
        selectedIndus,
        multipleVal,
        industry: company.industry,
        ebidta,
      };

      const response = await dispatch(updateMilestones(payload));
      let index;

      financialData.find((fd, i) => {
        if (fd._id === response.data.eaPeriodId) {
          index = i;
        }
      });

      if (index) {
        let copyFinancialData = { ...financialData };

        if (copyFinancialData[index].balanceSheet?.capx) {
          copyFinancialData[index].balanceSheet.capx = capx;
        } else {
          copyFinancialData[index].capx = capx;
        }

        if (copyFinancialData[index].financialData?.familyExcess) {
          copyFinancialData[index].financialData.familyExcess = familyExcess;
        } else {
          copyFinancialData[index].familyExcess = familyExcess;
        }

        dispatch({
          type: "UPDATE_FINANCIAL_DATA",
          payload: {
            data: Object.values(copyFinancialData),
          },
        });
      }

      fetchPoints();
    } catch (error) {
      console.log("erororrr", error);
    }
  };

  const handleClose = () => {
    if (!deleteLoading) setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    await dispatch(deleteMileStone(mileStoneId));
    fetchPoints();
    setDeleteLoading(false);
    setDeleteModalOpen(false);
  };

  const handleClickDelete = (simpleVal) => {
    setMileStoneId(simpleVal._id);
    setDeleteModalOpen(true);
  };

  const handleClickEdit = (item) => {
    setMileStoneId(item._id);
    setIsMSEdit(true);
    setModalOpen(true);

    // const targetPeriod = periods.find((p) => p._id === item.markerPeriodId);

    setMilestoneData({
      // periodId: targetPeriod._id,
      // periodName: targetPeriod.periodName,
      date: item.date,
      name: item.name,
    });
  };

  return (
    <React.Fragment>
      <Layout>
        <CardWrapper>
          <Grid
            item
            container
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography className="hidden_cash_subTitle">
              Earnings Approach{" "}
              <InfoOutlinedIcon
                onClick={() => handlInfo("svf")}
                className="infor_icon"
              />
            </Typography>
            <div
              style={{
                display: "flex",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  padding: "8px",
                }}
              >
                Select Period To Adjust
              </Typography>
              <Select
                value={filteredData}
                label="Period"
                onClick={(e) => {}}
                onChange={handleChange}
                className="financial_data_select"
              >
                {periods?.map((period, index) => {
                  if (period.dateType === "Year")
                    return (
                      <MenuItem
                        className="adjust_period_menu_icons"
                        key={index}
                        value={period._id}
                      >
                        {period.periodName}
                      </MenuItem>
                    );
                })}
              </Select>
            </div>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: "40px" }}>
            <Grid item xs={8} style={{ paddingRight: 40 }}>
              {!simpleValLoading ? (
                graphDate?.datesArray?.length ? (
                  <Box
                    ref={simpleValGraphRef}
                    sx={{ padding: "10px 10px 30px 10px" }}
                  >
                    <MilestonesLineGraph
                      simpleValData={simpleValData}
                      graphDate={graphDate}
                      setSimpleValData={setSimpleValData}
                      dummySimpleVal={dummySimpleVal}
                      setDummySimpleVal={setDummySimpleVal}
                      isGraphUpdate={isGraphUpdate}
                      setIsGraphUpdate={setIsGraphUpdate}
                      handleSelectedMarkerVals={handleSelectedMarkerVals}
                      totalEquity={totalEquity}
                    />
                  </Box>
                ) : (
                  <Typography
                    style={{
                      padding: "100px 0 0 200px",
                      color: "#b7b7b7",
                    }}
                  >
                    Add milestones to see graph
                  </Typography>
                )
              ) : (
                <CircularProgress />
              )}
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                // backgroundColor: "pink",
                paddingRight: 30,
                paddingLeft: 40,
                display: "grid",
                alignItems: "center",
                borderLeft: "1px solid rgb(231, 231, 231)",
              }}
            >
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  style={{ fontSize: 18, color: "gray", paddingTop: "4px" }}
                >
                  Date
                </Typography>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    className="simple_valuation_graph_inputs"
                    disabled={true}
                    value={eaDate}
                  />
                </label>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  style={{ fontSize: 18, color: "gray", paddingTop: "4px" }}
                >
                  EBITDA
                </Typography>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: 30,
                      color: "gray",
                      marginRight: 20,
                    }}
                  >
                    -
                  </span>
                  <CurrencyInput
                    disabled={true}
                    value={ebidta ? ebidta : ""}
                    className="simple_valuation_graph_inputs"
                  />
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    color: "gray",
                    fontWeight: "700",
                    paddingTop: "4px",
                  }}
                >
                  (Cap X)
                </Typography>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: 30,
                      color: "gray",
                      marginRight: 20,
                    }}
                  >
                    -
                  </span>
                  <CurrencyInput
                    value={capx ? capx : ""}
                    onChange={handleInputCapX}
                    className="simple_valuation_graph_inputs_bold"
                  />
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    color: "gray",
                    fontWeight: "700",
                    paddingTop: "4px",
                  }}
                >
                  Family Excess
                </Typography>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: 30,
                      color: "gray",
                      marginRight: 20,
                    }}
                  >
                    +
                  </span>
                  <CurrencyInput
                    value={familyExcess ? familyExcess : ""}
                    onChange={handleInputFamEx}
                    className="simple_valuation_graph_inputs_bold"
                  />
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    color: "gray",
                    paddingTop: "4px",
                    marginTop: "16px",
                    fontWeight: "700",
                  }}
                >
                  Multiple
                </Typography>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: 30,
                      color: "gray",
                      marginRight: 20,
                    }}
                  >
                    x
                  </span>
                  <Grid>
                    <Typography className="subtitle_financial_data">
                      Industry Average: {initialMultipleVal}
                    </Typography>

                    <CurrencyInput
                      value={multipleVal ? multipleVal : ""}
                      onChange={handleMulipleValChange}
                      className="simple_valuation_graph_inputs_bold"
                    />
                  </Grid>
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  style={{ fontSize: 18, color: "gray", paddingTop: "4px" }}
                >
                  Market Value
                </Typography>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: 30,
                      color: "gray",
                      marginRight: 20,
                    }}
                  >
                    =
                  </span>
                  {console.log("marketVal", marketVal)}
                  <CurrencyInput
                    disabled={true}
                    value={marketVal ? marketVal : ""}
                    className="simple_valuation_graph_inputs"
                  />
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  style={{ fontSize: 18, color: "gray", paddingTop: "4px" }}
                >
                  (Long Term Debt)
                </Typography>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: 30,
                      color: "gray",
                      marginRight: 20,
                    }}
                  >
                    -
                  </span>
                  <CurrencyInput
                    disabled={true}
                    value={longTermDebt ? longTermDebt : ""}
                    className="simple_valuation_graph_inputs"
                  />
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  style={{ fontSize: 18, color: "gray", paddingTop: "4px" }}
                >
                  EQUITY VALUE
                </Typography>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: 30,
                      color: "gray",
                      marginRight: 20,
                    }}
                  >
                    =
                  </span>
                  <CurrencyInput
                    disabled={true}
                    value={equityValue ? equityValue : ""}
                    className="simple_valuation_graph_inputs"
                  />
                </label>
              </Grid>
            </Grid>
          </Grid>
          <Box style={{ height: 30, width: "100%" }} />
          <Grid item container xs={12}>
            <Grid
              container
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                paddingRight: 30,
              }}
            >
              <Button onClick={handleSavePoint} className="home_add_company">
                Submit
              </Button>
            </Grid>
          </Grid>
          <CardWrapper>
            <Typography variant="h5">Major Events</Typography>
            <Box style={{ height: 25, width: "100%" }} />
            <CardWrapper border="1px solid #E7E7E7">
              <Grid item container xs={12}>
                <Box ref={simpleValMilestonesRef} style={{ width: "100%" }}>
                  <Grid
                    container
                    item
                    xs={12}
                    className="home_table_heading_row"
                  >
                    <Grid item xs={2}>
                      Event ID
                    </Grid>
                    <Grid item xs={4}>
                      Event
                    </Grid>
                    <Grid item xs={2}>
                      Date
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      Edit
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      Delete
                    </Grid>
                  </Grid>

                  {simpleValDataSeqSort?.length ? (
                    simpleValDataSeqSort?.map((simpleVal, index) =>
                      simpleVal.name ? (
                        <Grid
                          item
                          key={index + 100}
                          container
                          xs={12}
                          className="home_table_data_row d-flex ai-center"
                        >
                          <Grid
                            item
                            xs={2}
                            style={
                              simpleVal.marketVal
                                ? {
                                    fontSize: 16,
                                  }
                                : {
                                    fontSize: 16,
                                    color: "red",
                                  }
                            }
                          >
                            {simpleVal.seqnumber}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={
                              simpleVal.marketVal
                                ? {
                                    fontSize: 16,
                                  }
                                : {
                                    fontSize: 16,
                                    color: "red",
                                  }
                            }
                          >
                            {simpleVal.name}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={
                              simpleVal.marketVal
                                ? {
                                    fontSize: 16,
                                  }
                                : {
                                    fontSize: 16,
                                    color: "red",
                                  }
                            }
                          >
                            {simpleVal.date}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={
                              simpleVal.marketVal
                                ? {
                                    fontSize: 16,
                                    textAlign: "center",
                                  }
                                : {
                                    fontSize: 16,
                                    color: "red",
                                    textAlign: "center",
                                  }
                            }
                          >
                            <img
                              onClick={() => handleClickEdit(simpleVal)}
                              src={EditIcon}
                              style={{ cursor: "pointer" }}
                              alt="edit icon"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={
                              simpleVal.marketVal
                                ? {
                                    fontSize: 16,
                                    textAlign: "center",
                                  }
                                : {
                                    fontSize: 16,
                                    color: "red",
                                    textAlign: "center",
                                  }
                            }
                          >
                            <img
                              onClick={() => handleClickDelete(simpleVal)}
                              src={TrashIcon}
                              style={{ cursor: "pointer" }}
                              alt="delete icon"
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )
                    )
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        color: "gray",
                        padding: "20px 0px 4px 0",
                      }}
                    >
                      Add New Milestones
                    </p>
                  )}
                </Box>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    paddingRight: 30,
                  }}
                >
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setIsMSEdit(false);
                      setMilestoneData(InitMilestoneData);
                      setModalOpen(true);
                    }}
                    className="home_add_company"
                  >
                    Add Event
                  </Button>
                </Grid>
              </Grid>
            </CardWrapper>
          </CardWrapper>
          <Grid container xs={12}>
            <Accordian
              noteData={simpleValNotes}
              key1="simpleValNotes"
              notesRef={simpleValNotesRef}
              setIsGraphUpdate={setIsGraphUpdate}
            />
          </Grid>
        </CardWrapper>
        <AddMilestoneModal
          open={modalOpen}
          handleClose={handleMilestoneClose}
          years={periods}
          milestoneData={milestoneData}
          setMilestoneData={setMilestoneData}
          handleSaveMilestone={handleSaveMilestone}
          isMSEdit={isMSEdit}
        />
      </Layout>
      <SnackBar open={openSnackbar} snackType={snackType} msg={message} />
      <Modal
        open={deleteModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Typography variant="h6" align="center">
              Are you sure you want to Delete Milestone?
            </Typography>

            <Button
              className="login-btn"
              sx={{ float: "left" }}
              onClick={handleDelete}
              disabled={deleteLoading}
            >
              Yes
              {deleteLoading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5%",
                  }}
                />
              ) : (
                ""
              )}
            </Button>
            <Button
              className="login-btn"
              disabled={deleteLoading}
              sx={{ float: "right" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </>
      </Modal>
    </React.Fragment>
  );
};
export default SimpleValuation;
