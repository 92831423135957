const initialState = {
  startWithEndData: null, // Data related to Start With End
  loading: false, // Loading state for asynchronous actions
  error: null, // Error state to handle any errors
  transferableValues: [], // Array to store transferable values
};

const startWithEndReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_WITH_END_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_START_WITH_END":
      return {
        ...state,
        startWithEndData: action.payload,
        loading: false,
        error: null,
      };

    case "ADD_TRANSFERABLE_VALUE":
      return {
        ...state,
        startWithEndData: {
          ...state.startWithEndData,
          transferableValues: action.payload.transferableValues,
        },
        loading: false,
        error: null,
      };

    case "START_WITH_END_ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case "STOP_LOADING":
      return {
        ...state,
        loading: false,
      };

    case "INIT_STATES":
      return {
        ...state,
        ...initialState,
      };

    case "EMPTY_STATE":
      return {
        ...state,
        startWithEndData: null,
        error: null,
        transferableValues: [],
      };

    default:
      return state;
  }
};

export default startWithEndReducer;
