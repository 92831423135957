import React, { useCallback, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { GRAPH_TOOLTIP_SETTINGS } from "../../../utils/constants";
// import { Chart as CanvasJS } from "chartjs-node-canvas";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function YouVSOwnGoal({
  isGraphUpdate,
  setIsGraphUpdate,
  setPdfGraph,
  pdfGraph,
  industryAverage,
  selectedYearData,
  periodData,
}) {
  const operatingExpense =
    (periodData.incomeStatement.salesinUSD *
      (parseInt(periodData.ownerGoal.grossProfitePercentage) -
        periodData.ownerGoal.netProfitePercentage)) /
      100 -
    periodData.incomeStatement.operatingExpense;

  const labels = [
    "SALES TO ASSETS",
    "GROSS PROFIT",
    // "NET PROFIT",
    "OPERATING EXPENSE",
    "INVENTORY TURNOVER",
    "AR TURNOVER",
    "AP TURNOVER",
  ];

  const data = [
    Math.ceil(selectedYearData.salesToAssetsinUSD_FIO1),
    Math.ceil(selectedYearData.grossProfitinUSD_FIO2),
    // Math.ceil(selectedYearData.netProfitinUSD_FIO3),
    Math.ceil(operatingExpense),
    Math.ceil(selectedYearData.inventoryinUSD_FIO4),
    Math.ceil(selectedYearData.accountsReceivableinUSD_FIO5),
    Math.ceil(selectedYearData.accountsPayableinUSD_FIO6),
  ];

  const values = [
    selectedYearData.salesToAssetsinUSD_FIO1,
    selectedYearData.grossProfitinUSD_FIO2,
    // selectedYearData.netProfitinUSD_FIO3,
    operatingExpense,
    selectedYearData.inventoryinUSD_FIO4,
    selectedYearData.accountsReceivableinUSD_FIO5,
    selectedYearData.accountsPayableinUSD_FIO6,
  ];

  const [sum, setSum] = useState(
    selectedYearData.salesToAssetsinUSD_FIO1 +
      selectedYearData.grossProfitinUSD_FIO2 +
      // selectedYearData.netProfitinUSD_FIO3 +
      operatingExpense +
      selectedYearData.inventoryinUSD_FIO4 +
      selectedYearData.accountsReceivableinUSD_FIO5 +
      selectedYearData.accountsPayableinUSD_FIO6
  );

  const [checkIndexes, setCheckedIndexes] = useState([]);
  const [chartData, setChartData] = useState({
    labels: labels,
    datasets: [
      {
        label: "Hidden Cash Opportunities",
        data: data,

        backgroundColor: (ctx) => (ctx.raw < 0 ? "red" : "green"),
      },
    ],
  });

  useEffect(() => {
    setChartData({
      labels: [
        "SALES TO ASSETS",
        "GROSS PROFIT",
        // "NET PROFIT",
        "OPERATING EXPENSE",
        "INVENTORY TURNOVER",
        "AR TURNOVER",
        "AP TURNOVER",
      ],
      datasets: [
        {
          label: "Hidden Cash Opportunities",
          data: [
            Math.ceil(selectedYearData.salesToAssetsinUSD_FIO1),
            Math.ceil(selectedYearData.grossProfitinUSD_FIO2),
            // Math.ceil(selectedYearData.netProfitinUSD_FIO3),
            Math.ceil(operatingExpense),
            Math.ceil(selectedYearData.inventoryinUSD_FIO4),
            Math.ceil(selectedYearData.accountsReceivableinUSD_FIO5),
            Math.ceil(selectedYearData.accountsPayableinUSD_FIO6),
          ],

          backgroundColor: (ctx) => (ctx.raw < 0 ? "red" : "green"),
        },
      ],
    });

    setSum(
      selectedYearData.salesToAssetsinUSD_FIO1 +
        selectedYearData.grossProfitinUSD_FIO2 +
        // selectedYearData.netProfitinUSD_FIO3 +
        operatingExpense +
        selectedYearData.inventoryinUSD_FIO4 +
        selectedYearData.accountsReceivableinUSD_FIO5 +
        selectedYearData.accountsPayableinUSD_FIO6
    );
    setCheckedIndexes([]);
  }, [selectedYearData]);

  const isNegative = sum < 0 ? true : false;

  const options = {
    plugins: {
      title: {
        display: true,
        text: `YOU VERSUS YOUR OWN GOALS - HIDDEN CASH ${
          isNegative ? "(" : ""
        }$${Math.ceil(Math.abs(sum)).toLocaleString()}${isNegative ? ")" : ""}`,
        font: {
          size: 20,
        },
      },
      tooltip: {
        enabled: true,
        titleFont: {
          size: GRAPH_TOOLTIP_SETTINGS.titleFont, 
        },
        bodyFont: {
          size: GRAPH_TOOLTIP_SETTINGS.bodyFont, 
        },
        padding: GRAPH_TOOLTIP_SETTINGS.padding, 
      },
    },
    responsive: true,
    // maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          color: (context) => {
            const zeroLine = context.tick.value;
            const gridColor = zeroLine === 0 ? "#000" : "#efefef";
            return gridColor;
          },
        },
      },
    },
  };

  const handleHideBar = (e, index) => {
    let currCheckedIndexes;
    if (e.target.checked) {
      currCheckedIndexes = [...checkIndexes, index];
    } else {
      currCheckedIndexes = checkIndexes.filter((ind) => ind !== index);
    }

    setCheckedIndexes(currCheckedIndexes);

    const newLabels = labels.filter(
      (label, inde) => !currCheckedIndexes.includes(inde)
    );
    const newDatasets = [
      {
        label: "Hidden Cash Opportunities",
        data: data.filter((data, inde) => !currCheckedIndexes.includes(inde)),

        backgroundColor: (ctx) => (ctx.raw < 0 ? "red" : "green"),
      },
    ];

    setChartData({
      labels: newLabels,
      datasets: newDatasets,
    });

    let newSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!currCheckedIndexes.includes(i)) newSum = newSum + values[i];
    }
    setSum(newSum);

    setIsGraphUpdate(!isGraphUpdate);
  };

  // useEffect(() => {
  //   canvasJS.renderChart({
  //     type: "bar",
  //     data: chartData,
  //     options: options,
  //   });
  // }, []);

  // const downloadURL = () => {
  //   const imageLink = document.createElement("a");
  //   const canvas = document.getElementsByTagName("canvas");
  //   imageLink.href = canvas[0].toDataURL();
  //   setPdfGraph({ ...pdfGraph, ownGoal: imageLink.href });
  // };

  return (
    <div>
      <Bar options={options} data={chartData} style={{ minHeight: "100%" }} />
      <Grid container justifyContent="center" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(0)}
              onClick={(e) => handleHideBar(e, 0)}
            />
          }
          label="Sales to Assets"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(1)}
              onClick={(e) => handleHideBar(e, 1)}
            />
          }
          label="Gross Profit"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(2)}
              onClick={(e) => handleHideBar(e, 2)}
            />
          }
          // label="Net Profit"
          label="Operating Expense"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(3)}
              onClick={(e) => handleHideBar(e, 3)}
            />
          }
          label="Inventory Turnover"
        />{" "}
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(4)}
              onClick={(e) => handleHideBar(e, 4)}
            />
          }
          label="AR Turnover"
        />{" "}
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(5)}
              onClick={(e) => handleHideBar(e, 5)}
            />
          }
          label="AP Turnover"
        />
      </Grid>
    </div>
  );
}
