import axios from "axios";
import { BASE_URL } from "../../utils/config";

export const fetchHistoricalVals = (payload) => async () => {
  const response = await axios.post(
    `${BASE_URL}/fetchHistoricalValues`,
    payload
  );

  return response.data;
};
