import React, { useState, useEffect } from "react";
import { Typography, Box, Container, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/index.js";
import SnackBar from "../../components/snackBar";
import Logo from "../../images/logo2.png";
import whiteLogo from "../../images/logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import { singin } from "../../redux/actions/user.js";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [visibility, setVisibility] = useState(false);

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const { userLoading } = useSelector((state) => state.userReducer);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && token !== "undefined") navigate("/home");
  }, []);

  const validationSchema = yup.object({
    email: yup.string().required("Enter your email"),
    password: yup.string().required("Enter your password"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      dispatch(singin(values, navigate));
    },
  });

  return (
    <React.Fragment>
      <Header page="login" />
      <div className="main_bg">
        <Container>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "space-between",
                marginTop: "200px",
              }}
            >
              {/* <h1 className="login-head-text">
              Clear{" "}
              <span style={{ color: "#00c4f4", fontSize: "70px" }}> Path</span>{" "}
              to Cash
            </h1> */}
              <img src={whiteLogo} alt="logo" width="90%" height="90px" />

              {/* <p className="login-head-des">
              More Money, More Time, Better Business
            </p> */}
              {/* <p style={{ color: "white" }}>
              Privacy | T&C. Cash Flow Mike | All Rights Reserved
            </p> */}
            </Grid>
            <Grid item xs={12} lg={5} mt={4} mb={3}>
              <form onSubmit={formik.handleSubmit} className="login-bg">
                <Box className="main_div3" sx={{ padding: "40px 50px" }}>
                  <Box className="login-header">
                    <Typography
                      className="trans_typo1"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      Login
                    </Typography>
                  </Box>
                  <Typography className="main_typo3" sx={{ mt: 2 }}>
                    Email
                  </Typography>
                  <input
                    className="customize-input1"
                    placeholder="Enter email"
                    color="white"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <small className="red">
                    {formik.touched.email && formik.errors.email}
                  </small>
                  <Typography className="main_typo3" sx={{ mt: 2 }}>
                    Password
                  </Typography>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <input
                      className="customize-input1"
                      placeholder="Enter password"
                      color="white"
                      name="password"
                      type={visibility ? "text" : "password"}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    {!visibility ? (
                      <VisibilityIcon
                        onClick={() => setVisibility(true)}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "40%",
                          color: "#03111e",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => setVisibility(false)}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "40%",
                          color: "#03111e",
                        }}
                      />
                    )}
                  </div>
                  <small className="red">
                    {formik.touched.password && formik.errors.password}
                  </small>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      className="login-btn"
                      disabled={userLoading}
                      type="submit"
                    >
                      Log in
                      {userLoading ? (
                        <CircularProgress
                          color="inherit"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "5%",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Button>
                  </Box>

                  <Typography
                    sx={{
                      color: "#03111e",
                      textAlign: "center",
                      fontSize: "14px",
                      mt: 3,
                    }}
                  >
                    Don't have an account?{" "}
                    <Link
                      to={"/signup"}
                      style={{
                        cursor: "pointer",
                        fontWeight: "600",
                        color: "#1976d2",
                      }}
                    >
                      Signup
                    </Link>{" "}
                    OR{" "}
                    <Link
                      to={"/reset-password"}
                      style={{
                        cursor: "pointer",
                        fontWeight: "600",
                        color: "#1976d2",
                      }}
                    >
                      Reset Password
                    </Link>
                  </Typography>
                  <a href="https://cashflowmike.com/" target="blank">
                    {" "}
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img src={Logo} alt="" width="40%" />
                    </Box>
                  </a>
                </Box>
              </form>
            </Grid>
          </Grid>
          <SnackBar open={openSnackbar} snackType={snackType2} msg={message2} />
        </Container>
      </div>
    </React.Fragment>
  );
}
