import React, { useState, useEffect } from 'react'
import { Button, Grid, Checkbox } from '@mui/material'
import axios from 'axios'
import { BASE_URL } from '../../utils/config'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'

import SnackBar from '../../components/snackBar'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function Plan({ price, price_id, text, duration }) {
  const navigate = useNavigate()

  const handlePlanSelect = () => {
    navigate(`/subscription?plan=${text}`)
  }

  return (
    <div style={{ backgroundColor: 'white' }} className='payment-card'>
      <h2
        style={{
          marginBottom: '0px',
          fontWeight: '600',
        }}
      >
        {text}
      </h2>

      <h1 style={{ marginBottom: '5px', marginTop: '0px', fontSize: '40px' }}>
        ${price}
      </h1>
      <h4 style={{ marginTop: '5px', fontWeight: '600' }}>{duration}</h4>
      <Button
        className='plan_button'
        style={{
          backgroundColor: 'rgb(47, 158, 68)',
        }}
        onClick={handlePlanSelect}
      >
        <b>Continue</b>
      </Button>
    </div>
  )
}
