import React from "react";
import { Header, Sidebar } from "../components";
import { Grid } from "@mui/material";

export const Layout = ({ children, title }) => {
  return (
    <React.Fragment>
      <head>
        <title>{title}</title>
      </head>

      <Grid container xs={12}>
        <Grid item xs={2} style={{ height: "100vh" }}>
          <Sidebar />
        </Grid>
        <Grid container item xs={10} style={{ height: "100vh" }}>
          <Grid item xs={12} style={{ height: "88px" }}>
            <Header />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              height: "calc(100vh - 88px)",
              padding: 30,
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            className="hide_sideBar"
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
