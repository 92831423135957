import axios from "axios";
import { BASE_URL } from "../../utils/config";

export const fetchYears = (payload) => async () => {
  const response = await axios.post(`${BASE_URL}/dateType`, payload);
  return response.data.years;
};

export const fetchRangDate = (payload) => async () => {
  const response = await axios.post(`${BASE_URL}/rangeData`, payload);
  return response.data;
};
