import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { roundUpToNearestThousand } from "../../utils/utilFunctions";
import formateDate from "../../utils/formateDate";
import { addYears } from "../../utils/utilFunctions";

const OurPlan = ({
  yearsUntilExit,
  latestEquity,
  currSavingGoal,
  monthlyGoal,
  createdAt,
  formateWithCurrency,
  conversionThousandToK,
  firstEquity,
}) => {
  const [blueValue, setBlueValue] = useState(50); // Blue section value
  const [greenValue, setGreenValue] = useState(50); // Green section value

  const totalValue = blueValue + greenValue; // Total value

  const bluePercentage = (blueValue / totalValue) * 100;
  const greenPercentage = (greenValue / totalValue) * 100;

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // Measure the container width on mount and resize
  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box position="relative" width="100%" ref={containerRef}>
      {/* Progress bar */}
      <Box
        display="flex"
        width="100%"
        height="150px"
        overflow="hidden"
        position="relative"
      >
        {/* Blue section */}
        <Box
          bgcolor="deepskyblue"
          width={`${bluePercentage}%`}
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="white"
          marginRight="5px" // Margin between blue and green
        >
          <Typography>{`${
            monthlyGoal ? conversionThousandToK(monthlyGoal) : 0
          } Per Month For ${yearsUntilExit * 12} Months`}</Typography>
        </Box>

        {/* Green section */}
        <Box
          bgcolor="mediumseagreen"
          width={`${greenPercentage}%`}
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="white"
        >
          <Typography>{`${
            firstEquity ? formateWithCurrency(firstEquity) : 0
          } Original Value of Business`}</Typography>
        </Box>

        {/* "End" label at the end of the blue section */}
      </Box>
      <Box mt={1}>
        <Typography
          position="absolute"
          //   bottom="-20px"
          left={`${(bluePercentage / 100) * containerWidth - 50}px`} // Exact pixel position at the end of blue section
          transform="translateX(-50%)"
          variant="body2"
          color="textPrimary"
        >
          {formateDate(
            addYears(
              createdAt ? createdAt : Date.now(),
              parseInt(yearsUntilExit ? yearsUntilExit : "0")
            )
          )}
        </Typography>
      </Box>

      {/* "Start" text */}
      <Box display="flex" justifyContent="flex-start" mt={1}>
        <Typography variant="body2" color="textPrimary">
          {formateDate(createdAt ? createdAt : Date.now())}
        </Typography>
      </Box>
    </Box>
  );
};

export default OurPlan;
