import React, { useState, useEffect } from "react";
import { Typography, Box, Container, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/index.js";
import SnackBar from "../../components/snackBar/index.jsx";
import Logo from "../../images/logo2.png";
import whiteLogo from "../../images/logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import { singin } from "../../redux/actions/user.js";
import { resetPswdEmail } from "../../redux/actions/user.js";
import { clearSnack } from "../../utils/utilFunctions.js";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const [email, setEmail] = useState("");

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const { userLoading } = useSelector((state) => state.userReducer);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && token !== "undefined") navigate("/home");
  }, []);

  const validationSchema = yup.object({
    email: yup.string().required("Enter your email"),
    password: yup.string().required("Enter your password"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      dispatch(singin(values, navigate));
    },
  });

  const handleSendCode = async () => {
    if (!email) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Please enter email!",
        },
      });
      clearSnack(dispatch);
      return;
    }

    await dispatch(resetPswdEmail({ email }));
  };

  return (
    <React.Fragment>
      <Header page="login" />
      <div className="main_bg">
        <Container>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "space-between",
                marginTop: "200px",
              }}
            >
              <img src={whiteLogo} alt="logo" width="90%" height="90px" />
            </Grid>
            <Grid item xs={12} lg={5} mt={4} mb={3}>
              <Box className="main_div3" sx={{ padding: "40px 50px" }}>
                <Box className="login-header">
                  <Typography
                    className="trans_typo1"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Reset Password
                  </Typography>
                </Box>
                <Typography className="main_typo3" sx={{ mt: 2 }}>
                  Enter email here
                </Typography>
                <input
                  className="customize-input1"
                  placeholder="Enter email"
                  color="white"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="login-btn"
                    disabled={userLoading}
                    type="submit"
                    onClick={handleSendCode}
                  >
                    Send code
                    {userLoading ? (
                      <CircularProgress
                        color="inherit"
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "5%",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </Box>

                <Typography
                  sx={{
                    color: "#03111e",
                    textAlign: "center",
                    fontSize: "14px",
                    mt: 3,
                  }}
                >
                  Back to{" "}
                  <Link
                    to={"/signup"}
                    style={{
                      cursor: "pointer",
                      fontWeight: "600",
                      color: "#1976d2",
                    }}
                  >
                    Signup
                  </Link>{" "}
                  OR{" "}
                  <Link
                    to={"/"}
                    style={{
                      cursor: "pointer",
                      fontWeight: "600",
                      color: "#1976d2",
                    }}
                  >
                    Login
                  </Link>
                </Typography>
                <a href="https://cashflowmike.com/" target="blank">
                  {" "}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img src={Logo} alt="" width="40%" />
                  </Box>
                </a>
              </Box>
            </Grid>
          </Grid>
          <SnackBar open={openSnackbar} snackType={snackType2} msg={message2} />
        </Container>
      </div>
    </React.Fragment>
  );
}
