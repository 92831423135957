import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { clearSnack } from "../../utils/utilFunctions";

export const COMP_LOADING = "COMP_LOADING";
export const EDIT_COMP_LOADING = "EDIT_COMP_LOADING";
export const COMPANIES = "COMPANIES";
export const SET_SELECTED_COMP = "SET_SELECTED_COMP";
export const SET_FILTERED_COMP = "SET_FILTERED_COMP";
export const EDIT_COMP_LOADING_STOP = "EDIT_COMP_LOADING_STOP";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const ADD_NEW_COMP = "ADD_NEW_COMP";
export const LOADING_NEW_COMP = "LOADING_NEW_COMP";
export const COMP_LOADING_STOP = "COMP_LOADING_STOP";
export const DESELECTED_COMP = "DESELECTED_COMP";
export const ACCESS_COMP_LOADING = "ACCESS_COMP_LOADING";
export const ACCESS_COMP_LOADING_STOP = "ACCESS_COMP_LOADING_STOP";

export const OPEN_SNACK = "OPEN_SNACK";
export const CLEAR = "CLEAR";

export const INIT_STATES = "INIT_STATES";

export const iniateStatesCompany = () => async (dispatch) => {
  dispatch({
    type: INIT_STATES,
  });
};

export const fetchIndustryAvg = (payload) => async (dispatch) => {
  const response = await axios.post(`${BASE_URL}/fetchIndustryRatio`, payload);
  return response?.data?.data;
};

export const fetchSubAndMemEmails = (userId) => async () => {
  const response = await axios.get(
    `${BASE_URL}/fetchSubAndMemEmails/${userId}`
  );
  console.log("resfetchSubAndMemEmailsponse", response.data);
  return response.data;
};

export const UpdateMikeDemoComp = () => (dispatch) => {
  axios
    .get(`${BASE_URL}/updateDemoComp`)
    .then((res) => {
      dispatch({
        type: OPEN_SNACK,
        payload: {
          snackType: "success",
          message: "Demo Company will update for users within 5 minutes",
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: OPEN_SNACK,
        payload: {
          snackType: "error",
          message: "Something went wrong with the Demo Company update",
        },
      });
      clearSnack(dispatch);
    });
};

export const fetchCompanies = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: COMP_LOADING,
    });

    const response = await axios.post(`${BASE_URL}/company/user`, payload);

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    dispatch({
      type: COMPANIES,
      payload: response.data.data,
      status: "success",
    });

    clearSnack(dispatch);
  } catch (error) {
    dispatch({
      type: COMP_LOADING_STOP,
    });

    console.log("error insude  company actiuoin", error);
    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
  }
};

export const addNewCompany = (payload) => async (dispatch) => {
  try {
    // dispatch({
    //   type: COMP_LOADING,
    // });

    const response = await axios.post(`${BASE_URL}/company/add`, payload);

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    dispatch({
      type: ADD_NEW_COMP,
      payload: response.data.data,
      status: "success",
    });

    clearSnack(dispatch);
    return true;
  } catch (error) {
    console.log("error insude  company actiuoin", error);

    // dispatch({
    //   type: COMP_LOADING_STOP,
    // });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
    return false;
  }
};

export const updateCompanyAccess = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: ACCESS_COMP_LOADING,
    });

    const response = await axios.post(
      `${BASE_URL}/company/updateAccess`,
      payload
    );

    dispatch({
      type: COMPANIES,
      payload: response.data.data,
      status: "success",
    });

    dispatch({
      type: ACCESS_COMP_LOADING_STOP,
    });
  } catch (error) {
    dispatch({
      type: COMP_LOADING_STOP,
    });

    dispatch({
      type: EDIT_COMP_LOADING_STOP,
    });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
    return false;
  }
};

export const updateCompany = (payload, companyId) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COMP_LOADING,
    });

    const response = await axios.patch(
      `${BASE_URL}/company/update/${companyId}`,
      payload
    );

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    dispatch({
      type: COMPANIES,
      payload: response.data.data,
      status: "success",
    });
    // clearSnack(dispatch);

    dispatch({
      type: EDIT_COMP_LOADING_STOP,
    });

    clearSnack(dispatch);
    return true;
  } catch (error) {
    console.log("error insude  company actiuoin", error);

    dispatch({
      type: COMP_LOADING_STOP,
    });

    dispatch({
      type: EDIT_COMP_LOADING_STOP,
    });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
    return false;
  }
};

export const deleteCompany = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: COMP_LOADING,
    });

    const response = await axios.post(`${BASE_URL}/company/delete`, payload);

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "success",
        message: response.data.message,
      },
    });

    // dispatch({
    //   type: COMPANIES,
    //   payload: response.data.data,
    //   status: "success",
    // });
    clearSnack(dispatch);
  } catch (error) {
    console.log("error insude  company actiuoin", error);

    dispatch({
      type: COMP_LOADING_STOP,
    });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
    return false;
  }
};

export const deselectCompany = () => async (dispatch) => {
  dispatch({
    type: DESELECTED_COMP,
    payload: {
      selectedCompany: {},
      selectedCompanyId: "",
    },
  });
};

export const setSelectedCompany = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SELECTED_COMP,
      payload: payload,
    });
  } catch (error) {
    console.log("error insude  company actiuoin", error);

    dispatch({
      type: COMP_LOADING_STOP,
    });

    //snackbar
    dispatch({
      type: OPEN_SNACK,
      payload: {
        snackType: "error",
        message: error.response.data.message,
      },
    });
    clearSnack(dispatch);
    return false;
  }
};

export const clearFilteredCompanies = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_FILTER,
    });
  } catch (error) {
    console.log("error in setFilteredCompanies", error);
  }
};

export const setFilteredCompanies = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_FILTERED_COMP,
      payload,
    });
  } catch (error) {
    console.log("error in setFilteredCompanies", error);
  }
};
