import React, { useState, useEffect } from "react";
import { Grid, Button, Modal, Box, Typography, Checkbox } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../components/snackBar";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Subscription({ price, duration, priceId, subsText }) {
  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [snackType, setSnackType] = useState(false);
  const [message, setMessage] = useState("");
  const [retrievePayment, setRetrievePayment] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [isCoupon, setIsCoupon] = useState(false);
  const [isCouponDetails, setIsCouponDetails] = useState(false);
  const [couponDetails, setCouponDetails] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [promotionCode, setPromotionCode] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#efefef",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  useEffect(() => {
    setRetrievePayment(localStorage.getItem("paymentStatus"));
  }, []);

  useEffect(() => {
    setTimeout(() => setPageLoad(false), 800);
  }, []);

  const handleCouponInput = (e) => {
    const value = e.target.value;
    // const regex = /^[a-zA-Z0-9]{0,8}$/
    // console.log('regex.test(value)', regex.test(value))
    // if (regex.test(value)) {
    // setCoupon(value)
    setPromotionCode(value);
    // }
  };
  const handleClose = () => setIsCouponDetails(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (retrievePayment === true) {
      setOpen(true);
      setSnackType("error");
      setMessage("Payment already Exist");
    } else {
      if (!stripe || !elements) {
        return;
      }

      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: localStorage.getItem("userEmail"),
        },
      });

      // console.log('resultresultresult', result)

      if (result.error) {
        setLoading(false);
        setOpen(true);
        setSnackType("");
        setMessage(result.error.message);
        return;
      }

      axios
        .post(`${BASE_URL}/subscription`, {
          payment_method: result.paymentMethod.id,
          email: localStorage.getItem("userEmail"),
          plan: priceId,
          // coupon,
          promotionCode,
        })
        .then((res) => {
          setLoading(false);

          if (res) {
            setOpen(true);
            setSnackType("success");
            setMessage("Payment successful!");
            localStorage.setItem("paymentStatus", true);

            if (isCoupon) {
              setIsCouponDetails(true);
              setCouponDetails(res.data.couponDetails);
              return;
            }

            setTimeout(() => {
              navigate("/home");
            }, 5000);
          }
        })
        .catch((error) => {
          console.log("error", error);

          setOpen(true);
          setMessage(error.response.data.message);

          setLoading(false);
          const { client_secret, status } = error;
          if (status === "requires_action") {
            stripe.confirmCardPayment(client_secret).then(function (result) {});
            setOpen(true);
            setMessage(error.result);
            setSnackType("");
          }
        });
    }
  };
  return (
    <div style={{ backgroundColor: "white" }} className="payment-card">
      <Grid container>
        <Grid item xs={12}>
          {pageLoad ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "360px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <form id="payment-form" onSubmit={handleSubmit}>
              <div className="checkout-info">
                <div>
                  <p className="payment-title">Billing Details</p>

                  <div>
                    <h2 style={{ marginBottom: "0px", fontWeight: "600" }}>
                      Checkout
                    </h2>
                    <p style={{ marginTop: "0px", marginBottom: "5px" }}>
                      Fill the required fields to proceed checkout
                    </p>
                    <h1 style={{ marginBottom: "5px", marginTop: "0px" }}>
                      ${price}/{duration}
                    </h1>
                    <h4 style={{ marginTop: "5px", fontWeight: "600" }}>
                      {subsText}
                    </h4>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "3%",
                        border: "1px solid gray",
                      }}
                    >
                      <CardElement />
                    </div>
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <Checkbox
                        onChange={(e) => setIsCoupon(e.target.checked)}
                      />{" "}
                      <p style={{ marginTop: "5px" }}>Add Coupon</p>
                    </div>
                    {isCoupon ? (
                      <input
                        className="coupon-input"
                        placeholder="Enter coupon code here"
                        onChange={handleCouponInput}
                        value={promotionCode}
                      />
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        marginTop: "5%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        className="anchor-btn"
                        style={{
                          padding: "5px 8px",
                          borderRadius: "5px",
                          width: "100px",
                        }}
                        disabled={loading}
                        onClick={() => navigate("/select")}
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        className="anchor-btn"
                        style={{
                          padding: "5px 8px",
                          borderRadius: "5px",
                          width: "100px",
                        }}
                        disabled={loading}
                      >
                        {isCoupon ? "Continue" : "Pay"}
                        {loading ? (
                          <CircularProgress
                            color="inherit"
                            style={{
                              height: "20px",
                              width: "20px",
                              marginLeft: "5%",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Grid>
      </Grid>
      <SnackBar
        setOpen={setOpen}
        open={open}
        snackType={snackType ? "success" : "error"}
        msg={message}
      />
      <Modal
        open={isCouponDetails}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Typography variant="h6" align="center">
              Coupon Details
            </Typography>
            <div style={{ marginTop: "20px" }}>
              <Typography variant="subtitle1">
                Discount Type:{" "}
                <span style={{ marginLeft: "20%" }}>
                  {couponDetails.discountType}
                </span>
              </Typography>
              <Typography variant="subtitle1">
                Discounted Amount:{" "}
                <span style={{ marginLeft: "25%" }}>
                  {couponDetails.discountedAmount}
                </span>
              </Typography>
              <Typography variant="subtitle1">
                Discount You Get:{" "}
                <span style={{ marginLeft: "24%" }}>
                  ${couponDetails?.discountYouGet?.toFixed(2)}
                </span>
              </Typography>
              <Typography variant="subtitle1">
                Due Today:{" "}
                <span style={{ marginLeft: "34%" }}>
                  ${couponDetails?.newPrice?.toFixed(2)}
                </span>
              </Typography>
            </div>
            <Button
              className="login-btn"
              sx={{ float: "right" }}
              onClick={() => navigate("/cashMining")}
            >
              Pay
            </Button>
          </Box>
        </>
      </Modal>
    </div>
  );
}
