import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'
import Plan from './plan'

export default function Select() {
  const navigate = useNavigate()
  const monthPrice = '60.00'
  const yearPrice = '575.00'
  const monthlyPrice = 'price_1MyNBgC3mzByfEvxXrGvtDsu'
  const yearlyPrice = 'price_1NHrQjC3mzByfEvx3nK8Oroz'

  useEffect(() => {
    const tokenn = localStorage.getItem('token')
    if (!tokenn) {
      navigate('/')
    }
  }, [navigate])

  return (
    <div
      className='main_bg'
      style={{
        minHeight: '100vh',
      }}
    >
      <Grid container justifyContent='center'>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            padding: '45px',
            borderRadius: '20px',
            backgroundColor: 'white',
            border: '5px solid white',
          }}
          className='payment-box-bg'
        >
          <h2>Please select the Plan</h2>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Plan
                  price={monthPrice}
                  price_id={monthlyPrice}
                  text='MONTHLY'
                  duration='PER MONTH'
                />
              </Grid>
              <Grid item xs={6}>
                <Plan
                  price={yearPrice}
                  price_id={yearlyPrice}
                  text='ANNUAL'
                  duration='PER YEAR'
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
