export const UPDATE_PDF_DATA = "UPDATE_PDF_DATA";
export const SAVE_NOTES_PDF = "SAVE_NOTES_PDF";
export const GRAPH_LOADING = "GRAPH_LOADING";
export const STOP_GRAPH_LOADING = "STOP_GRAPH_LOADING";

export const setNotesPdf = (payload) => (dispatch) => {
  dispatch({
    type: SAVE_NOTES_PDF,
    payload: {
      key: payload.key,
      value: payload.value,
    },
  });
};

export const updatePDFData = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_PDF_DATA,
    payload,
  });
};
