import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { clearSnack } from "../../utils/utilFunctions";

export const fetchMilestones = (companyId) => async () => {
  const response = await axios.get(`${BASE_URL}/simpleVal/${companyId}`);
  return response.data;
};

export const updateMilestoneData = (payload, milestoneId) => async () => {
  await axios.put(
    `${BASE_URL}/simpleVal/update/milestone/data/${milestoneId}`,
    payload
  );
};

export const addMilestones = (payload) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/simpleVal/milestone`,
      payload
    );
    return response.data;
  } catch (error) {
    dispatch({
      type: "OPEN_SNACK",
      payload: {
        snackType: "error",
        message: error?.response?.data?.message || "Server error",
      },
    });
    clearSnack(dispatch);
  }
};

export const deleteMileStone = (mileStoneId) => async (dispatch) => {
  const resp = await axios.delete(`${BASE_URL}/simpleVal/${mileStoneId}`);
  return resp;
};

export const updateMilestones = (payload) => async () => {
  const response = await axios.put(`${BASE_URL}/simpleVal/update`, payload);
  return response.data;
};
