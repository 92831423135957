import React, { useState, useEffect } from "react";
import "./style.css";
import { Layout } from "../../layout";
import { CardWrapper } from "../../components";
import formateDate from "../../utils/formateDate";
import {
  Grid,
  Typography,
  Button,
  Tabs,
  Tab,
  Modal,
  Box,
  Divider,
  Select,
  FormControl,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CurrencyInput from "./CurrencyInput.jsx";
import NumberInput from "./NumberInput.jsx";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Edit from "../../images/edit.png";
import Delete from "../../images/delete.png";
import dayjs from "dayjs";
import {
  fetchCompanyDetails,
  updateCompanyDetails,
  addCompanyDetails,
  checkPeriodDuplicate,
} from "../../redux/actions/companyDetails.js";
import { deletePeriod } from "../../redux/actions/companyDetails";
import SnackBar from "../../components/snackBar";
import { removeComma } from "../../utils/utilFunctions.js";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { handlInfo } from "../../utils/utilFunctions.js";

import {
  PERIOD_DURATION,
  OWNER_GOAL,
  INCOME_STATEMENT,
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  HIDDEN_CASH_OWNER_GOAL,
  HIDDEN_CASH_INCOME_STATEMENT,
  HIDDEN_CASH_BALANCE_SHEET,
  FAST_MONEY_INCOME_STATEMENT,
  FAST_MONEY_BALANCE_SHEET,
  HOME_RUN_INCOME_STATEMENT,
  HOME_RUN_BALANCE_SHEET,
  HOME_RUN_CASH_FLOW_STATEMENT,
  FINANCIAL_DATA_INCOME_STATEMENT,
  FINANCIAL_DATA_BALANCE_SHEET,
  FINANCIAL_DATA_CASH_FLOW_STATEMENT,
  SVS_INCOME_STATEMENT,
  SVS_BALANCE_SHEET,
  FINANCIAL_DATA_OWNER_GOAL,
} from "../../utils/constants.js";

import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/system";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  border: "none",
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    order: -1,
    marginRight: theme.spacing(1),
  },
}));

export const OPEN_SNACK = "OPEN_SNACK";
export const CLEAR = "CLEAR";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#efefef",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const FinancialData = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isTab = searchParams.get("tab");

  const {
    periods,
    industryAverage,
    financialData,
    company,
    companyDetailsLoading,
  } = useSelector((state) => state.companyDetailReducer);

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deletPeriodModelOpen, setDeletPeriodModelOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [periodData, setPeriodData] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [dateType, setDateType] = useState("");
  const [periodName, setPeriodName] = useState("");

  const [financialDataId, setFinancialDataId] = useState("");

  const [value, setValue] = useState(isTab ? parseInt(isTab) : 0);

  const [preDefinedOwnerGaolVals, setPreDefinedOwnerGaolVals] = useState({});

  useEffect(() => {
    if (financialData.length) {
      const periodWithOwnerGoalVals = financialData.find(
        (data) =>
          data.ownerGoal.accountsPayableTurnoverRate ||
          data.ownerGoal.accountsReceivableTurnoverRate ||
          data.ownerGoal.grossProfitePercentage ||
          data.ownerGoal.inventoryTurnoverRate ||
          data.ownerGoal.netProfitePercentage ||
          data.ownerGoal.salesToAssets
      );
      if (periodWithOwnerGoalVals) {
        setPreDefinedOwnerGaolVals(periodWithOwnerGoalVals.ownerGoal);
      }
    }
  }, [financialData]);

  const validationSchema = yup.object({
    salesToAssets: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    grossProfitePercentage: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    netProfitePercentage: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    inventoryTurnoverRate: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    accountsReceivableTurnoverRate: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    accountsPayableTurnoverRate: yup.lazy(() => {
      if (value === 0 || value === 1)
        return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    ebidta: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    roa: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    roi: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    currentRatio: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    quickRatio: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    debtToEquityRatio: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    dcoh: yup.lazy(() => {
      if (value === 0) return yup.number().required("All Fields are required");
      return yup.number().nullable().optional();
    }),
    salesinUSD: yup.lazy(() => {
      if (value !== 4) return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    totalAssetsinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    grossFixedAssets: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    grossProfiteinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    netProfiteBeforeTaxinUSD: yup.lazy(() => {
      if (
        value === 0 ||
        value === 1 ||
        value === 3 ||
        value === 4 ||
        value === 5
      )
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    operatingActivities: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    investingActivities: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    financingActivities: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    operatingExpense: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 1 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    taxesPaid: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    interestExpense: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    depExpense: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    amortExpense: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    costOfGoodsSoldinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    inventoryinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    accountReceivableinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    accountPayableinUSD: yup.lazy(() => {
      if (value === 0 || value === 1 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    retainedEarnings: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    totalEquity: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    longTermDebt: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 4 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    totalLiabilities: yup.lazy(() => {
      if (value === 0 || value === 3 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    avgPayrollExpense: yup.lazy((value) => {
      if ((company?.isService && value === 2) || value === 5) {
        return yup.string().required("All Fields are required");
      }
      return yup.string().nullable().optional();
    }),
    daysInPayroll: yup.lazy((value) => {
      if ((company?.isService && value === 2) || value === 5) {
        return yup.string().required("All Fields are required");
      }
      return yup.string().nullable().optional();
    }),
    currenAssets: yup.lazy(() => {
      if (value === 0 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    cash: yup.lazy(() => {
      if (value === 0 || value === 5 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    currenLiabilities: yup.lazy(() => {
      if (value === 0 || value === 2 || value === 5)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    capx: yup.lazy(() => {
      if (value === 0 || value === 4)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
    familyExcess: yup.lazy(() => {
      if (value === 0 || value === 4)
        return yup.string().required("All Fields are required");
      return yup.string().nullable().optional();
    }),
  });

  const initialValues = {
    // owner goal values condition description: if no value is assigned it should show the value of any recent owner goal and if new period is selected it should show any recent owner goal

    salesToAssets: Object.keys(periodData).length
      ? periodData.ownerGoal.salesToAssets
        ? periodData.ownerGoal.salesToAssets
        : preDefinedOwnerGaolVals?.salesToAssets
        ? preDefinedOwnerGaolVals?.salesToAssets
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.salesToAssets
        ? preDefinedOwnerGaolVals?.salesToAssets
        : ""
      : "",
    grossProfitePercentage: Object.keys(periodData).length
      ? periodData.ownerGoal.grossProfitePercentage
        ? periodData.ownerGoal.grossProfitePercentage
        : preDefinedOwnerGaolVals?.grossProfitePercentage
        ? preDefinedOwnerGaolVals?.grossProfitePercentage
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.grossProfitePercentage
        ? preDefinedOwnerGaolVals?.grossProfitePercentage
        : ""
      : "",

    netProfitePercentage: Object.keys(periodData).length
      ? periodData.ownerGoal.netProfitePercentage
        ? periodData.ownerGoal.netProfitePercentage
        : preDefinedOwnerGaolVals?.netProfitePercentage
        ? preDefinedOwnerGaolVals?.netProfitePercentage
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.netProfitePercentage
        ? preDefinedOwnerGaolVals?.netProfitePercentage
        : ""
      : "",
    // inventoryTurnoverRate: Object.keys(periodData).length
    //   ? periodData.ownerGoal.inventoryTurnoverRate &&
    //     parseInt(periodData.ownerGoal.inventoryTurnoverRate)
    //     ? (365 / periodData.ownerGoal.inventoryTurnoverRate).toFixed(0)
    //     : periodData.ownerGoal.inventoryTurnoverRate
    //   : "",

    inventoryTurnoverRate: Object.keys(periodData).length
      ? periodData.ownerGoal.inventoryTurnoverRate
        ? parseInt(periodData.ownerGoal.inventoryTurnoverRate)
          ? (
              365 / parseInt(periodData.ownerGoal.inventoryTurnoverRate)
            ).toFixed(0)
          : periodData.ownerGoal.inventoryTurnoverRate
        : preDefinedOwnerGaolVals?.inventoryTurnoverRate
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.inventoryTurnoverRate
        ? preDefinedOwnerGaolVals?.inventoryTurnoverRate
        : ""
      : "",

    // inventoryTurnoverRate: Object.keys(periodData).length
    //   ? periodData.ownerGoal.inventoryTurnoverRate &&
    //     parseInt(periodData.ownerGoal.inventoryTurnoverRate)
    //     ? (365 / periodData.ownerGoal.inventoryTurnoverRate).toFixed(0)
    //     : periodData.ownerGoal.inventoryTurnoverRate
    //   : preDefinedOwnerGaolVals?.inventoryTurnoverRate
    //   ? preDefinedOwnerGaolVals.inventoryTurnoverRate &&
    //     parseInt(preDefinedOwnerGaolVals.inventoryTurnoverRate)
    //     ? (365 / preDefinedOwnerGaolVals.inventoryTurnoverRate).toFixed(0)
    //     : preDefinedOwnerGaolVals.inventoryTurnoverRate
    //   : "",

    // accountsReceivableTurnoverRate: Object.keys(periodData).length
    //   ? periodData.ownerGoal.accountsReceivableTurnoverRate &&
    //     parseInt(periodData.ownerGoal.accountsReceivableTurnoverRate)
    //     ? (365 / periodData.ownerGoal.accountsReceivableTurnoverRate).toFixed(0)
    //     : periodData.ownerGoal.accountsReceivableTurnoverRate
    //   : "",

    accountsReceivableTurnoverRate: Object.keys(periodData).length
      ? periodData.ownerGoal.accountsReceivableTurnoverRate
        ? parseInt(periodData.ownerGoal.accountsReceivableTurnoverRate)
          ? (
              365 /
              parseInt(periodData.ownerGoal.accountsReceivableTurnoverRate)
            ).toFixed(0)
          : periodData.ownerGoal.accountsReceivableTurnoverRate
        : preDefinedOwnerGaolVals?.accountsReceivableTurnoverRate
        ? parseInt(preDefinedOwnerGaolVals.accountsReceivableTurnoverRate)
          ? (
              365 /
              parseInt(preDefinedOwnerGaolVals.accountsReceivableTurnoverRate)
            ).toFixed(0)
          : preDefinedOwnerGaolVals.accountsReceivableTurnoverRate
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.accountsReceivableTurnoverRate
        ? parseInt(preDefinedOwnerGaolVals.accountsReceivableTurnoverRate)
          ? (
              365 /
              parseInt(preDefinedOwnerGaolVals.accountsReceivableTurnoverRate)
            ).toFixed(0)
          : preDefinedOwnerGaolVals.accountsReceivableTurnoverRate
        : ""
      : "",

    // accountsPayableTurnoverRate: Object.keys(periodData).length
    //   ? periodData.ownerGoal.accountsPayableTurnoverRate &&
    //     parseInt(periodData.ownerGoal.accountsPayableTurnoverRate)
    //     ? (365 / periodData.ownerGoal.accountsPayableTurnoverRate).toFixed(0)
    //     : periodData.ownerGoal.accountsPayableTurnoverRate
    //   : "",

    accountsPayableTurnoverRate: Object.keys(periodData).length
      ? periodData.ownerGoal.accountsPayableTurnoverRate
        ? parseInt(periodData.ownerGoal.accountsPayableTurnoverRate)
          ? (
              365 / parseInt(periodData.ownerGoal.accountsPayableTurnoverRate)
            ).toFixed(0)
          : periodData.ownerGoal.accountsPayableTurnoverRate
        : preDefinedOwnerGaolVals?.accountsPayableTurnoverRate
        ? parseInt(preDefinedOwnerGaolVals.accountsPayableTurnoverRate)
          ? (
              365 /
              parseInt(preDefinedOwnerGaolVals.accountsPayableTurnoverRate)
            ).toFixed(0)
          : preDefinedOwnerGaolVals.accountsPayableTurnoverRate
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.accountsPayableTurnoverRate
        ? parseInt(preDefinedOwnerGaolVals.accountsPayableTurnoverRate)
          ? (
              365 /
              parseInt(preDefinedOwnerGaolVals.accountsPayableTurnoverRate)
            ).toFixed(0)
          : preDefinedOwnerGaolVals.accountsPayableTurnoverRate
        : ""
      : "",

    ebidta: Object.keys(periodData).length
      ? periodData.ownerGoal.ebidta
        ? periodData.ownerGoal.ebidta
        : preDefinedOwnerGaolVals?.ebidta
        ? preDefinedOwnerGaolVals?.ebidta
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.ebidta
        ? preDefinedOwnerGaolVals?.ebidta
        : ""
      : "",

    roa: Object.keys(periodData).length
      ? periodData.ownerGoal.roa
        ? periodData.ownerGoal.roa
        : preDefinedOwnerGaolVals?.roa
        ? preDefinedOwnerGaolVals?.roa
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.roa
        ? preDefinedOwnerGaolVals?.roa
        : ""
      : "",

    roi: Object.keys(periodData).length
      ? periodData.ownerGoal.roi
        ? periodData.ownerGoal.roi
        : preDefinedOwnerGaolVals?.roi
        ? preDefinedOwnerGaolVals?.roi
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.roi
        ? preDefinedOwnerGaolVals?.roi
        : ""
      : "",

    currentRatio: Object.keys(periodData).length
      ? periodData.ownerGoal.currentRatio
        ? periodData.ownerGoal.currentRatio
        : preDefinedOwnerGaolVals?.currentRatio
        ? preDefinedOwnerGaolVals?.currentRatio
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.currentRatio
        ? preDefinedOwnerGaolVals?.currentRatio
        : ""
      : "",

    quickRatio: Object.keys(periodData).length
      ? periodData.ownerGoal.quickRatio
        ? periodData.ownerGoal.quickRatio
        : preDefinedOwnerGaolVals?.quickRatio
        ? preDefinedOwnerGaolVals?.quickRatio
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.quickRatio
        ? preDefinedOwnerGaolVals?.quickRatio
        : ""
      : "",

    debtToEquityRatio: Object.keys(periodData).length
      ? periodData.ownerGoal.debtToEquityRatio
        ? periodData.ownerGoal.debtToEquityRatio
        : preDefinedOwnerGaolVals?.debtToEquityRatio
        ? preDefinedOwnerGaolVals?.debtToEquityRatio
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.debtToEquityRatio
        ? preDefinedOwnerGaolVals?.debtToEquityRatio
        : ""
      : "",

    dcoh: Object.keys(periodData).length
      ? periodData.ownerGoal.dcoh
        ? periodData.ownerGoal.dcoh
        : preDefinedOwnerGaolVals?.dcoh
        ? preDefinedOwnerGaolVals?.dcoh
        : ""
      : selectedPeriod
      ? preDefinedOwnerGaolVals?.dcoh
        ? preDefinedOwnerGaolVals?.dcoh
        : ""
      : "",
    salesinUSD: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.salesinUSD)
      : "",
    totalAssetsinUSD: Object.keys(periodData).length
      ? parseInt(periodData.balanceSheet.totalAssetsinUSD)
      : "",
    grossFixedAssets: Object.keys(periodData).length
      ? periodData.balanceSheet.grossFixedAssets ?? ""
      : "",
    grossProfiteinUSD: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.grossProfiteinUSD)
      : "",
    netProfiteBeforeTaxinUSD: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.netProfiteBeforeTaxinUSD)
      : "",
    operatingActivities: Object.keys(periodData).length
      ? periodData.cashFlow?.operatingActivities ?? ""
      : "",
    investingActivities: Object.keys(periodData).length
      ? periodData.cashFlow?.investingActivities ?? ""
      : "",
    financingActivities: Object.keys(periodData).length
      ? periodData.cashFlow?.financingActivities ?? ""
      : "",
    operatingExpense: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.operatingExpense)
      : "",
    taxesPaid: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.taxesPaid)
      : "",
    interestExpense: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.interestExpense)
      : "",
    depExpense: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.depExpense)
      : "",
    amortExpense: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.amortExpense ?? "")
      : "",
    costOfGoodsSoldinUSD: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.costOfGoodsSoldinUSD)
      : "",
    inventoryinUSD: Object.keys(periodData).length
      ? periodData.balanceSheet.inventoryinUSD
      : "",
    capx: Object.keys(periodData).length
      ? parseInt(periodData.balanceSheet.capx) ?? ""
      : "",
    familyExcess: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.familyExcess ?? "")
      : "",
    accountReceivableinUSD: Object.keys(periodData).length
      ? periodData.balanceSheet.accountReceivableinUSD
      : "",
    accountPayableinUSD: Object.keys(periodData).length
      ? periodData.balanceSheet.accountPayableinUSD
      : "",
    retainedEarnings: Object.keys(periodData).length
      ? periodData.balanceSheet.retainedEarnings ?? ""
      : "",
    totalEquity: Object.keys(periodData).length
      ? periodData.balanceSheet.totalEquity ?? ""
      : "",
    longTermDebt: Object.keys(periodData).length
      ? periodData.balanceSheet.longTermDebt ?? ""
      : "",
    totalLiabilities: Object.keys(periodData).length
      ? periodData.balanceSheet.totalLiabilities ?? ""
      : "",
    avgPayrollExpense: Object.keys(periodData).length
      ? parseInt(periodData.incomeStatement.avgPayrollExpense)
      : "",
    daysInPayroll: Object.keys(periodData).length
      ? periodData.incomeStatement.daysInPayroll ?? ""
      : "",
    currenAssets: Object.keys(periodData).length
      ? periodData.balanceSheet.currenAssets
      : "",
    cash: Object.keys(periodData).length
      ? periodData.balanceSheet.cash ?? ""
      : "",
    currenLiabilities: Object.keys(periodData).length
      ? periodData.balanceSheet.currenLiabilities
      : "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,

    onSubmit: async (values, errors) => {
      if (!periodName) {
        dispatch({
          type: OPEN_SNACK,
          payload: {
            snackType: "error",
            message: "Please select period",
          },
        });
        setTimeout(() => {
          dispatch({
            type: CLEAR,
          });
        }, 3000);
        return;
      }

      const {
        salesToAssets,
        grossProfitePercentage,
        netProfitePercentage,
        inventoryTurnoverRate,
        accountsReceivableTurnoverRate,
        salesinUSD,
        accountsPayableTurnoverRate,
        ebidta,
        roa,
        roi,
        currentRatio,
        quickRatio,
        debtToEquityRatio,
        dcoh,
        grossProfiteinUSD,
        netProfiteBeforeTaxinUSD,
        operatingExpense,
        taxesPaid,
        interestExpense,
        depExpense,
        amortExpense,
        costOfGoodsSoldinUSD,
        avgPayrollExpense,
        daysInPayroll,
        inventoryinUSD,
        totalAssetsinUSD,
        grossFixedAssets,
        accountReceivableinUSD,
        accountPayableinUSD,
        retainedEarnings,
        totalEquity,
        longTermDebt,
        totalLiabilities,
        currenLiabilities,
        currenAssets,
        cash,
        operatingActivities,
        investingActivities,
        financingActivities,
        familyExcess,
        capx,
      } = values;

      const inputDate = new Date(selectedDate);
      const options = { day: "numeric", month: "long", year: "numeric" };
      const outputDateStr = inputDate.toLocaleDateString("en-US", options);

      const checkAndFormate = (val) => {
        // salesToAssets ? salesToAssets.toFixed(2) : "",
        if (val === 0 || val) {
          if (typeof val !== "string") {
            return val.toFixed(2);
          } else {
            return parseInt(val).toFixed(2);
          }
        }
        return val;
      };

      const payload = {
        ownerGoal: {
          salesToAssets: checkAndFormate(salesToAssets),
          grossProfitePercentage: checkAndFormate(grossProfitePercentage),
          netProfitePercentage: checkAndFormate(netProfitePercentage),
          inventoryTurnoverRate: parseInt(inventoryTurnoverRate)
            ? Math.ceil(365 / checkAndFormate(inventoryTurnoverRate))
            : inventoryTurnoverRate,
          accountsReceivableTurnoverRate: parseInt(
            accountsReceivableTurnoverRate
          )
            ? Math.ceil(365 / checkAndFormate(accountsReceivableTurnoverRate))
            : accountsReceivableTurnoverRate,
          accountsPayableTurnoverRate: parseInt(accountsPayableTurnoverRate)
            ? Math.ceil(365 / checkAndFormate(accountsPayableTurnoverRate))
            : accountsPayableTurnoverRate,
          ebidta: checkAndFormate(ebidta),
          roa: checkAndFormate(roa),
          roi: checkAndFormate(roi),
          currentRatio: checkAndFormate(currentRatio),
          quickRatio: checkAndFormate(quickRatio),
          debtToEquityRatio: checkAndFormate(debtToEquityRatio),
          dcoh: checkAndFormate(dcoh),
        },
        incomeStatement: {
          salesinUSD: removeComma(salesinUSD),
          grossProfiteinUSD: removeComma(grossProfiteinUSD),
          netProfiteBeforeTaxinUSD: removeComma(netProfiteBeforeTaxinUSD),
          operatingExpense: removeComma(operatingExpense),
          taxesPaid: removeComma(taxesPaid),
          interestExpense: removeComma(interestExpense),
          depExpense: removeComma(depExpense),
          amortExpense: removeComma(amortExpense),
          costOfGoodsSoldinUSD: removeComma(costOfGoodsSoldinUSD),
          avgPayrollExpense: removeComma(avgPayrollExpense),
          daysInPayroll: removeComma(daysInPayroll),
          familyExcess: removeComma(familyExcess),
        },
        cashFlow: {
          operatingActivities: removeComma(operatingActivities),
          investingActivities: removeComma(investingActivities),
          financingActivities: removeComma(financingActivities),
        },
        balanceSheet: {
          inventoryinUSD: removeComma(inventoryinUSD),
          totalAssetsinUSD: removeComma(totalAssetsinUSD),
          grossFixedAssets: removeComma(grossFixedAssets),
          accountReceivableinUSD: removeComma(accountReceivableinUSD),
          accountPayableinUSD: removeComma(accountPayableinUSD),
          retainedEarnings: removeComma(retainedEarnings),
          totalEquity: removeComma(totalEquity),
          longTermDebt: removeComma(longTermDebt),
          totalLiabilities: removeComma(totalLiabilities),
          currenAssets: removeComma(currenAssets),
          cash: removeComma(cash),
          currenLiabilities: removeComma(currenLiabilities),
          capx: removeComma(capx),
        },

        companyId: company._id,
        year: outputDateStr,
        NAICScode: company.NAICScode,
        periodName: periodName,
        dateType: dateType,
      };

      const payload2 = {
        companyId: company._id,
      };
      await dispatch(addCompanyDetails(payload));
      await dispatch(fetchCompanyDetails(payload2));
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDateChange = (event) => {
    setSelectedPeriod(event.target.value);
    let value = financialData.find((x) => x.year === event.target.value);
    if (value) {
      setPeriodData(value);
      setDateType(value.dateType);
      setSelectedDate(value.year);
      setPeriodName(value.periodName);
      setFinancialDataId(value._id);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeletPeriodModelClose = () => setDeletPeriodModelOpen(false);

  const handleEditDate = (date) => {
    let formattedDate = new Date(date);
    setSelectedDate(formattedDate);
    setIsEdit(true);
    handleOpen();
  };

  const handleDeleteDate = async () => {
    const payload = {
      hiddenCashId: periodData._id,
    };
    const payload2 = {
      companyId: company._id,
    };
    await dispatch(deletePeriod(payload));
    await dispatch(fetchCompanyDetails(payload2));
    handleDeletPeriodModelClose();
  };

  const handleEditRecord = () => {
    const payload = {
      hiddenCashId: financialDataId,
      periodName: periodName,
      dateType: dateType,
      periodDate: selectedDate,
      companyId: company._id,
    };

    dispatch(updateCompanyDetails(payload));
    const payload2 = {
      companyId: company._id,
    };
    dispatch(fetchCompanyDetails(payload2));
  };

  const handleSubmitPeriod = async () => {
    if (!selectedDate || !periodName || !dateType) {
      dispatch({
        type: OPEN_SNACK,
        payload: {
          snackType: "error",
          message: "Please enter all fields",
        },
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 3000);
      return;
    }

    if (isEdit) {
      handleEditRecord();
    }

    const payload = {
      year: selectedDate,
      periodName,
      dateType,
      companyId: company._id,
    };

    const respo = await dispatch(checkPeriodDuplicate(payload));

    if (respo) {
      const newPeriods = [
        ...periods,
        {
          date: formateDate(selectedDate),
          dateType,
          periodName,
        },
      ];

      dispatch({
        type: "ASSIGN_PERIODS",
        payload: {
          data: newPeriods,
        },
      });

      formik.resetForm();
      setSelectedPeriod();
      handleClose();

      setSelectedPeriod(formateDate(selectedDate));
    }
  };

  const handleClassForInputs = () => "disabled_tabs_content_input";
  // selectedPeriod ? "tabs_content_input" : "disabled_tabs_content_input";

  const handleIndustryAvg = (industryAverage, item) => {
    return parseInt(item.turnover)
      ? (365 / industryAverage[item.industryAvg]).toFixed(0)
      : industryAverage[item.industryAvg];
  };

  return (
    <React.Fragment>
      <Layout title="Financial Data">
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="financial_data_heading">
              {company.companyName}
            </Typography>
            <Typography className="financial_data_right_heading">
              NAICS Code: {company.NAICScode}
            </Typography>
          </Grid>
        </CardWrapper>
        <Box style={{ height: 30, width: "100%" }} />
        <CardWrapper>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 37,
            }}
          >
            <Typography className="home_my_companies">
              Financial Data{" "}
              <InfoOutlinedIcon
                onClick={() => handlInfo("financialData")}
                className="infor_icon"
              />
              <br />
              <span style={{ fontSize: 16 }}>
                <ol style={{ padding: "9px 0 0 16px" }}>
                  <li>
                    <b>
                      Create or select a period to enter data to from the
                      options on the right.
                    </b>
                  </li>
                  <li>
                    <b>
                      Select a calculation set below and enter the required data
                      fields.
                    </b>
                  </li>
                  <li>
                    <b>Press SUBMIT to save the data to the period.</b>
                  </li>
                </ol>
              </span>
            </Typography>
            <Button
              onClick={() => {
                handleOpen();
                setIsEdit(false);
                // setValue("");
              }}
              className="home_add_company"
            >
              <span style={{ fontSize: 30, marginRight: 10 }}>+</span> Add
              Period
            </Button>
          </Grid>
          <Grid container xs={12}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="All" {...a11yProps(0)} />
                  <Tab label="Hidden Cash" {...a11yProps(1)} />
                  <Tab label="Fast Money" {...a11yProps(2)} />
                  <Tab label="Home Run" {...a11yProps(3)} />
                  <Tab label="Simple Valuation" {...a11yProps(4)} />
                  <Tab label="Financial Doctor" {...a11yProps(5)} />
                </Tabs>

                <Select
                  onChange={handleDateChange}
                  onClick={(e) => {}}
                  value={selectedPeriod}
                  // label="years"
                  className="financial_data_select"
                >
                  {periods?.map((period, index) => {
                    return (
                      <MenuItem
                        className="adjust_period_menu_icons"
                        key={index}
                        value={period.date}
                      >
                        {period.periodName}
                        <span className="adjust-btn-size">
                          <Button onClick={() => handleEditDate(period.date)}>
                            <img src={Edit} alt="" height={20} />
                          </Button>
                          <Button
                            onClick={() => {
                              setDeletPeriodModelOpen(true);
                            }}
                          >
                            <img src={Delete} alt="" height={20} />
                          </Button>
                        </span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                {/* ALL */}
                <CustomTabPanel value={value} index={0}>
                  <Typography className="financial_data_sec_heading">
                    Owner`s Goal
                  </Typography>
                  <Grid container xs={12}>
                    {OWNER_GOAL.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography className="tabs_content_heading">
                            {item?.label}
                          </Typography>
                          {item.industryAvg ? (
                            <Typography className="subtitle_financial_data">
                              Industry Average:{" "}
                              {industryAverage &&
                                handleIndustryAvg(industryAverage, item)}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Box className="tabs_content_input_box">
                          {/* <input
                            type="number"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name]
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            style={{ backgroundColor: "red" }}
                            disabled={selectedPeriod ? false : true}
                          /> */}

                          <NumberInput
                            type="text"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name]
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            style={{ backgroundColor: "red" }}
                            disabled={selectedPeriod ? false : true}
                          />

                          {item.percentage ? (
                            <Box className="tabs_content_percent">%</Box>
                          ) : (
                            ""
                          )}
                          {item.turnover ? (
                            <Box className="tabs_content_percent">
                              {parseInt(formik.values[item.name])
                                ? `${(365 / formik.values[item.name]).toFixed(
                                    2
                                  )}x`
                                : "0.00x"}
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}

                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={handleClassForInputs()}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={"1234123"}
                                  // value={formik.values[item.name]} // removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={formik.handleChange}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}

                              <CurrencyInput
                                // className="tabs_content_input"
                                className={handleClassForInputs()}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]} removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={formik.handleChange}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>

                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating

                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Cash Flow Statement
                  </Typography>
                  <Grid container xs={12}>
                    {CASH_FLOW_STATEMENT.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>

                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* HIDDEN CASH */}
                <CustomTabPanel value={value} index={1}>
                  <Typography className="financial_data_sec_heading">
                    Owner`s Goal
                  </Typography>
                  <Grid container xs={12}>
                    {HIDDEN_CASH_OWNER_GOAL.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography className="tabs_content_heading">
                            {item?.label}
                          </Typography>
                          {item.industryAvg ? (
                            <Typography className="subtitle_financial_data">
                              Industry Average:{" "}
                              {industryAverage &&
                                handleIndustryAvg(industryAverage, item)}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Box className="tabs_content_input_box">
                          {/* <input
                            type="number"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            // value={formik.values[item.name]} removed becuase null values were not updating
                            value={
                              formik.values[item.name]
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          /> */}

                          <NumberInput
                            type="text"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            // value={formik.values[item.name]} removed becuase null values were not updating
                            value={
                              formik.values[item.name]
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />

                          {item.turnover ? (
                            <Box className="tabs_content_percent">
                              {parseInt(formik.values[item.name])
                                ? `${(365 / formik.values[item.name]).toFixed(
                                    2
                                  )}x`
                                : "0.00x"}
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {HIDDEN_CASH_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={handleClassForInputs()}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]} removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={formik.handleChange}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={handleClassForInputs()}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]} // removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={formik.handleChange}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {HIDDEN_CASH_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]}// removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* FAST MONEY */}
                <CustomTabPanel value={value} index={2}>
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {FAST_MONEY_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={handleClassForInputs()}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]} // removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={formik.handleChange}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={handleClassForInputs()}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]} // removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={formik.handleChange}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {FAST_MONEY_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]}// removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* HOME RUN */}
                <CustomTabPanel value={value} index={3}>
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {HOME_RUN_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={handleClassForInputs()}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]}// removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={formik.handleChange}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={handleClassForInputs()}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]}// removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={formik.handleChange}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {HOME_RUN_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Cash Flow Statement
                  </Typography>
                  <Grid container xs={12}>
                    {HOME_RUN_CASH_FLOW_STATEMENT.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]}// removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* SVS */}
                <CustomTabPanel value={value} index={4}>
                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {SVS_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={handleClassForInputs()}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]} // removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={formik.handleChange}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={handleClassForInputs()}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]}// removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={formik.handleChange}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {SVS_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]}// removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                {/* Financial Doctor */}
                <CustomTabPanel value={value} index={5}>
                  <CustomAccordion>
                    <CustomAccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="financial_data_sec_heading"
                    >
                      Owner`s Goal
                    </CustomAccordionSummary>
                    <AccordionDetails>
                      <Grid container xs={12}>
                        {FINANCIAL_DATA_OWNER_GOAL.map((item, index) => (
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              {item.industryAvg ? (
                                <Typography className="subtitle_financial_data">
                                  Industry Average:{" "}
                                  {industryAverage &&
                                    handleIndustryAvg(industryAverage, item)}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Box className="tabs_content_input_box">
                              {/* <input
                                type="number"
                                // className="tabs_content_input"
                                className={handleClassForInputs()}
                                placeholder={item?.placeholder}
                                name={item.name}
                                // value={formik.values[item.name]} // removed becuase null values were not updating
                                value={
                                  formik.values[item.name]
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={formik.handleChange}
                                disabled={selectedPeriod ? false : true}
                              /> */}

                              <NumberInput
                                type="text"
                                // className="tabs_content_input"
                                className={handleClassForInputs()}
                                placeholder={item?.placeholder}
                                name={item.name}
                                // value={formik.values[item.name]} // removed becuase null values were not updating
                                value={
                                  formik.values[item.name]
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={formik.handleChange}
                                disabled={selectedPeriod ? false : true}
                              />

                              {item.percentage ? (
                                <Box className="tabs_content_percent">%</Box>
                              ) : (
                                ""
                              )}
                              {item.turnover ? (
                                <Box className="tabs_content_percent">
                                  {parseInt(formik.values[item.name])
                                    ? `${(
                                        365 / formik.values[item.name]
                                      ).toFixed(2)}x`
                                    : "0.00x"}
                                </Box>
                              ) : (
                                ""
                              )}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </CustomAccordion>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />

                  <Typography className="financial_data_sec_heading">
                    Income Statement
                  </Typography>
                  <Grid container xs={12}>
                    {FINANCIAL_DATA_INCOME_STATEMENT.map((item, index) =>
                      item.name === "daysInPayroll" ||
                      item.name === "avgPayrollExpense" ? (
                        company.isService ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              style={{ padding: 20 }}
                              key={index + 100}
                            >
                              <Typography className="tabs_content_heading">
                                {item?.label}
                              </Typography>
                              <Box className="tabs_content_input_box">
                                {item.name !== "daysInPayroll" ? (
                                  <Box className="tabs_content_dollar">$</Box>
                                ) : (
                                  ""
                                )}
                                <CurrencyInput
                                  // className="tabs_content_input"
                                  className={handleClassForInputs()}
                                  placeholder={item?.placeholder}
                                  name={item.name}
                                  type="text"
                                  // value={formik.values[item.name]}// removed becuase null values were not updating
                                  value={
                                    formik.values[item.name] !== undefined &&
                                    formik.values[item.name] !== null &&
                                    formik.values[item.name] !== "" &&
                                    !(
                                      typeof formik.values[item.name] ===
                                        "number" &&
                                      isNaN(formik.values[item.name])
                                    )
                                      ? formik.values[item.name]
                                      : ""
                                  }
                                  onChange={formik.handleChange}
                                  disabled={selectedPeriod ? false : true}
                                />
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ padding: 20 }}
                            key={index + 100}
                          >
                            <Typography className="tabs_content_heading">
                              {item?.label}
                            </Typography>
                            <Box className="tabs_content_input_box">
                              {item.name !== "daysInPayroll" ? (
                                <Box className="tabs_content_dollar">$</Box>
                              ) : (
                                ""
                              )}
                              <CurrencyInput
                                // className="tabs_content_input"
                                className={handleClassForInputs()}
                                placeholder={item?.placeholder}
                                name={item.name}
                                type="text"
                                // value={formik.values[item.name]}// removed becuase null values were not updating
                                value={
                                  formik.values[item.name] !== undefined &&
                                  formik.values[item.name] !== null &&
                                  formik.values[item.name] !== "" &&
                                  !(
                                    typeof formik.values[item.name] ===
                                      "number" &&
                                    isNaN(formik.values[item.name])
                                  )
                                    ? formik.values[item.name]
                                    : ""
                                }
                                onChange={formik.handleChange}
                                disabled={selectedPeriod ? false : true}
                              />
                            </Box>
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Balance Sheet
                  </Typography>
                  <Grid container xs={12}>
                    {FINANCIAL_DATA_BALANCE_SHEET.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  <Box
                    style={{
                      width: "100%",
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.10)",
                      margin: "20px 0px",
                    }}
                  />
                  <Typography className="financial_data_sec_heading">
                    Cash Flow Statement
                  </Typography>
                  <Grid container xs={12}>
                    {FINANCIAL_DATA_CASH_FLOW_STATEMENT.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        style={{ padding: 20 }}
                        key={index + 100}
                      >
                        <Typography className="tabs_content_heading">
                          {item?.label}
                        </Typography>
                        <Box className="tabs_content_input_box">
                          <Box className="tabs_content_dollar">$</Box>
                          <CurrencyInput
                            // className="tabs_content_input"
                            className={handleClassForInputs()}
                            placeholder={item?.placeholder}
                            name={item.name}
                            type="text"
                            // value={formik.values[item.name]} // removed becuase null values were not updating
                            value={
                              formik.values[item.name] !== undefined &&
                              formik.values[item.name] !== null &&
                              formik.values[item.name] !== "" &&
                              !(
                                typeof formik.values[item.name] === "number" &&
                                isNaN(formik.values[item.name])
                              )
                                ? formik.values[item.name]
                                : ""
                            }
                            onChange={formik.handleChange}
                            disabled={selectedPeriod ? false : true}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CustomTabPanel>

                <Divider />
                <Grid
                  container
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    type="submit"
                    className="home_add_company"
                    style={{ marginTop: 30 }}
                    disabled={companyDetailsLoading}
                  >
                    Submit
                    {companyDetailsLoading ? (
                      <CircularProgress
                        color="inherit"
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "5%",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingRight: "30px",
                    paddingTop: "5px",
                  }}
                >
                  <small className="red" style={{ fontWeight: "600" }}>
                    {(formik.touched.salesToAssets &&
                      formik.errors.salesToAssets) ||
                    (formik.touched.grossProfitePercentage &&
                      formik.errors.grossProfitePercentage) ||
                    (formik.touched.netProfitePercentage &&
                      formik.errors.netProfitePercentage) ||
                    (formik.touched.inventoryTurnoverRate &&
                      formik.errors.inventoryTurnoverRate) ||
                    (formik.touched.accountsReceivableTurnoverRate &&
                      formik.errors.accountsReceivableTurnoverRate) ||
                    (formik.touched.salesinUSD && formik.errors.salesinUSD) ||
                    (formik.touched.totalAssetsinUSD &&
                      formik.errors.totalAssetsinUSD) ||
                    (formik.touched.grossFixedAssets &&
                      formik.errors.grossFixedAssets) ||
                    (formik.touched.grossProfiteinUSD &&
                      formik.errors.grossProfiteinUSD) ||
                    (formik.touched.netProfiteBeforeTaxinUSD &&
                      formik.errors.netProfiteBeforeTaxinUSD) ||
                    (formik.touched.operatingActivities &&
                      formik.errors.operatingActivities) ||
                    (formik.touched.investingActivities &&
                      formik.errors.investingActivities) ||
                    (formik.touched.financingActivities &&
                      formik.errors.financingActivities) ||
                    (formik.touched.operatingExpense &&
                      formik.errors.operatingExpense) ||
                    (formik.touched.taxesPaid && formik.errors.taxesPaid) ||
                    (formik.touched.interestExpense &&
                      formik.errors.interestExpense) ||
                    (formik.touched.depExpense && formik.errors.depExpense) ||
                    (formik.touched.amortExpense &&
                      formik.errors.amortExpense) ||
                    (formik.touched.costOfGoodsSoldinUSD &&
                      formik.errors.costOfGoodsSoldinUSD) ||
                    (formik.touched.inventoryinUSD &&
                      formik.errors.inventoryinUSD) ||
                    (formik.touched.capx && formik.errors.capx) ||
                    (formik.touched.familyExcess &&
                      formik.errors.familyExcess) ||
                    (formik.touched.accountReceivableinUSD &&
                      formik.errors.accountReceivableinUSD) ||
                    (formik.touched.accountPayableinUSD &&
                      formik.errors.accountPayableinUSD) ||
                    (formik.touched.retainedEarnings &&
                      formik.errors.retainedEarnings) ||
                    (formik.touched.totalEquity && formik.errors.totalEquity) ||
                    (formik.touched.longTermDebt &&
                      formik.errors.longTermDebt) ||
                    (formik.touched.totalLiabilities &&
                      formik.errors.totalLiabilities) ||
                    (company?.isService &&
                      formik.touched.avgPayrollExpense &&
                      formik.errors.avgPayrollExpense) ||
                    (formik.touched.currenAssets &&
                      formik.errors.currenAssets) ||
                    (formik.touched.cash && formik.errors.cash) ||
                    (formik.touched.currenLiabilities &&
                      formik.errors.currenLiabilities) ||
                    (company?.isService &&
                      formik.touched.daysInPayroll &&
                      formik.errors.daysInPayroll)
                      ? "All fields are required"
                      : ""}
                  </small>
                </Box>
              </form>
            </Box>
          </Grid>
        </CardWrapper>
      </Layout>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ textAlign: "center" }}>Enter Details</h2>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Period Name
              </Typography>
            </div>
            <input
              className="customize-input1"
              placeholder="Enter Period Name"
              value={periodName ? periodName : ""}
              color="white"
              name="periodName"
              type="text"
              onChange={(e) => {
                setPeriodName(e.target.value);
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="main_typo3" sx={{ mt: 1, width: "73%" }}>
                Last Day of Period
              </Typography>
              <Typography className="main_typo3" sx={{ mt: 1 }}>
                Date Type
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", gap: "10px", alignItems: "center" }}
              id="custom-picker"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(selectedDate)}
                  style={{ borderRadius: "22px" }}
                  // disabled={isEdit}
                  onChange={(newValue) => {
                    setSelectedDate(newValue.$d);
                  }}
                />
              </LocalizationProvider>
              <FormControl className="currencies-form">
                <Select
                  label="Date Type"
                  onChange={(e) => {
                    setDateType(e.target.value);
                  }}
                  sx={{ border: "1px solid", borderRadius: "22px" }}
                >
                  {PERIOD_DURATION.map((options, index) => {
                    return (
                      <MenuItem key={index} value={options.label}>
                        {options.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="login-btn"
                disabled={companyDetailsLoading}
                onClick={handleSubmitPeriod}
                id="period-details-btn"
              >
                Submit
                {companyDetailsLoading ? (
                  <CircularProgress
                    color="inherit"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "5%",
                    }}
                  />
                ) : (
                  ""
                )}
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>

      <Modal
        open={deletPeriodModelOpen}
        onClose={handleDeletPeriodModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Typography variant="h6" align="center">
              Are you sure you want to delete this period?
            </Typography>

            <Button
              className="login-btn"
              sx={{ float: "left" }}
              onClick={handleDeleteDate}
              // disabled={cancelSubLoading}
            >
              Yes
              {/* {cancelSubLoading ? (
                <CircularProgress
                  color="inherit"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5%",
                  }}
                />
              ) : (
                ""
              )} */}
            </Button>
            <Button
              className="login-btn"
              // disabled={cancelSubLoading}
              sx={{ float: "right" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </>
      </Modal>
      <SnackBar
        setOpen={setOpenSnackBar}
        open={openSnackbar}
        snackType={snackType2}
        msg={message2}
      />
    </React.Fragment>
  );
};
export default FinancialData;
