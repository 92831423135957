import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { GRAPH_TOOLTIP_SETTINGS } from "../../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function YouVsIndustryAvg({
  selectedYearDataVsThem,
  periodData,
  industryAverage,
}) {
  const operatingExpense =
    periodData.incomeStatement.salesinUSD *
      (parseInt(industryAverage.operatingExpense) / 100) -
    periodData.incomeStatement.operatingExpense;

  const labels = [
    "SALES TO ASSETS",
    "GROSS PROFIT",
    "OPERATING EXPENSE",
    "INVENTORY TURNOVER",
    "AR TURNOVER",
    "AP TURNOVER",
  ];

  const data = [
    Math.ceil(parseInt(selectedYearDataVsThem.salesToAssetsinUSD_FIN1)),
    Math.ceil(parseInt(selectedYearDataVsThem.grossProfitinUSD_FIN2)),
    // Math.ceil(parseInt(selectedYearDataVsThem.netProfitinUSD_FIN3)),
    Math.ceil(parseInt(operatingExpense)),
    Math.ceil(parseInt(selectedYearDataVsThem.inventoryinUSD_FIN4)),
    Math.ceil(parseInt(selectedYearDataVsThem.accountsReceivableinUSD_FIN5)),
    Math.ceil(parseInt(selectedYearDataVsThem.accountsPayableinUSD_FIN6)),
  ];

  const values = [
    selectedYearDataVsThem.salesToAssetsinUSD_FIN1,
    selectedYearDataVsThem.grossProfitinUSD_FIN2,
    // selectedYearDataVsThem.netProfitinUSD_FIN3,
    operatingExpense,
    selectedYearDataVsThem.inventoryinUSD_FIN4,
    selectedYearDataVsThem.accountsReceivableinUSD_FIN5,
    selectedYearDataVsThem.accountsPayableinUSD_FIN6,
  ];

  const [sum, setSum] = useState(
    selectedYearDataVsThem.salesToAssetsinUSD_FIN1 +
      selectedYearDataVsThem.grossProfitinUSD_FIN2 +
      // selectedYearDataVsThem.netProfitinUSD_FIN3 +
      operatingExpense +
      selectedYearDataVsThem.inventoryinUSD_FIN4 +
      selectedYearDataVsThem.accountsReceivableinUSD_FIN5 +
      selectedYearDataVsThem.accountsPayableinUSD_FIN6
  );

  const [checkIndexes, setCheckedIndexes] = useState([]);
  const [chartData, setChartData] = useState({
    labels: labels,
    datasets: [
      {
        label: "Hidden Cash Opportunities",
        data: data,

        backgroundColor: (ctx) => (ctx.raw < 0 ? "red" : "green"),
      },
    ],
  });

  useEffect(() => {
    setChartData({
      labels: [
        "SALES TO ASSETS",
        "GROSS PROFIT",
        "OPERATING EXPENSE",
        "INVENTORY TURNOVER",
        "AR TURNOVER",
        "AP TURNOVER",
      ],
      datasets: [
        {
          label: "Hidden Cash Opportunities",
          data: [
            Math.ceil(selectedYearDataVsThem.salesToAssetsinUSD_FIN1),
            Math.ceil(selectedYearDataVsThem.grossProfitinUSD_FIN2),
            // Math.ceil(selectedYearDataVsThem.netProfitinUSD_FIN3),
            Math.ceil(operatingExpense),
            Math.ceil(selectedYearDataVsThem.inventoryinUSD_FIN4),
            Math.ceil(selectedYearDataVsThem.accountsReceivableinUSD_FIN5),
            Math.ceil(selectedYearDataVsThem.accountsPayableinUSD_FIN6),
          ],

          backgroundColor: (ctx) => (ctx.raw < 0 ? "red" : "green"),
        },
      ],
    });

    setSum(
      selectedYearDataVsThem.salesToAssetsinUSD_FIN1 +
        selectedYearDataVsThem.grossProfitinUSD_FIN2 +
        // selectedYearDataVsThem.netProfitinUSD_FIN3 +
        operatingExpense +
        selectedYearDataVsThem.inventoryinUSD_FIN4 +
        selectedYearDataVsThem.accountsReceivableinUSD_FIN5 +
        selectedYearDataVsThem.accountsPayableinUSD_FIN6
    );
    setCheckedIndexes([]);
  }, [selectedYearDataVsThem]);

  const isNegative = sum < 0 ? true : false;

  const options = {
    plugins: {
      title: {
        display: true,
        text: `YOU VERSUS THE INDUSTRY AVERAGE - HIDDEN CASH ${
          isNegative ? "(" : ""
        }$${Math.ceil(Math.abs(sum)).toLocaleString()}${isNegative ? ")" : ""}`,
        font: {
          size: 20,
        },
      },
      tooltip: {
        enabled: true,
        titleFont: {
          size: GRAPH_TOOLTIP_SETTINGS.titleFont, 
        },
        bodyFont: {
          size: GRAPH_TOOLTIP_SETTINGS.bodyFont, 
        },
        padding: GRAPH_TOOLTIP_SETTINGS.padding, 
      },
    },
    responsive: true,
    // maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          color: (context) => {
            const zeroLine = context.tick.value;
            const gridColor = zeroLine === 0 ? "#000" : "#efefef";
            return gridColor;
          },
        },
      },
    },
    legend: {
      display: false, // Set to false to hide the legend
    },
  };

  const handleHideBar = (e, index) => {
    let currCheckedIndexes;
    if (e.target.checked) {
      currCheckedIndexes = [...checkIndexes, index];
    } else {
      currCheckedIndexes = checkIndexes.filter((ind) => ind !== index);
    }

    setCheckedIndexes(currCheckedIndexes);

    const newLabels = labels.filter(
      (label, inde) => !currCheckedIndexes.includes(inde)
    );
    const newDatasets = [
      {
        label: "Hidden Cash Opportunities",
        data: data.filter((data, inde) => !currCheckedIndexes.includes(inde)),

        backgroundColor: (ctx) => (ctx.raw < 0 ? "red" : "green"),
      },
    ];

    setChartData({
      labels: newLabels,
      datasets: newDatasets,
    });

    let newSum = 0;

    for (let i = 0; i < values.length; i++) {
      if (!currCheckedIndexes.includes(i)) newSum = newSum + values[i];
    }
    setSum(newSum);

    // setIsGraphUpdate(!isGraphUpdate);
  };

  // const downloadURL = () => {
  //   const imageLink = document.createElement("a");
  //   const canvas = document.getElementsByTagName("canvas");
  //   imageLink.href = canvas[1].toDataURL();
  //   setPdfGraph({ ...pdfGraph, industryAvg: imageLink.href });
  // };

  return (
    <div>
      <Bar options={options} data={chartData} style={{ minHeight: "100%" }} />
      <Grid container justifyContent="center" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(0)}
              onClick={(e) => handleHideBar(e, 0)}
            />
          }
          label="Sales to Assets"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(1)}
              onClick={(e) => handleHideBar(e, 1)}
            />
          }
          label="Gross Profit"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(2)}
              onClick={(e) => handleHideBar(e, 2)}
            />
          }
          label="Operating Expense"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(3)}
              onClick={(e) => handleHideBar(e, 3)}
            />
          }
          label="Inventory Turnover"
        />{" "}
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(4)}
              onClick={(e) => handleHideBar(e, 4)}
            />
          }
          label="AR Turnover"
        />{" "}
        <FormControlLabel
          control={
            <Checkbox
              checked={checkIndexes.includes(5)}
              onClick={(e) => handleHideBar(e, 5)}
            />
          }
          label="AP Turnover"
        />
      </Grid>
    </div>
  );
}
