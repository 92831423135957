import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import CheckoutForm from "../../components/checkout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../images/logo2.png";
import { useDispatch } from "react-redux";

import { iniateStatesNotes } from "../../redux/actions/notes.js";
import {
  iniateStatesCompany,
  setSelectedCompany,
} from "../../redux/actions/company.js";
import {
  fetchCompanyDetails,
  iniateStatesCompanyDetails,
} from "../../redux/actions/companyDetails.js";
import { iniateStatesSnackBar } from "../../redux/actions/snackbar.js";
import { iniateStatesUser } from "../../redux/actions/user.js";
import { signout } from "../../redux/actions/user.js";
import { Button } from "@material-ui/core";

export default function Subscription() {
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get("plan");
  const navigate = useNavigate();
  const value = "76.704";
  const monthPrice = "60.00";
  const yearPrice = "575.00";
  const monthlyPriceId = "App"; // monthly price id live stripe
  const yearlyPriceId = "price_1NO8gNC3mzByfEvxmgRJVJfg"; // yearly price id live stripe
  // const monthlyPriceId = "price_1MyNBgC3mzByfEvxXrGvtDsu";
  // const yearlyPriceId = "price_1NHrQjC3mzByfEvx3nK8Oroz";

  const stripePromise = loadStripe(
    // "pk_test_r1ZGYFathbyZKdQJKpnFrSGf00GRqreSIV"
    "pk_live_TRARsYun9DpHFmKi4BrBxlah00vB4JGQfk"
  );

  const [planPrice, setPlanPrice] = useState();
  const [priceId, setPriceId] = useState();
  const [duration, setDuration] = useState();
  const [subsText, setSubsText] = useState();

  useEffect(() => {
    if (plan === "MONTHLY") {
      setPlanPrice(monthPrice);
      setPriceId(monthlyPriceId);
      setDuration("Month");
      setSubsText("1 Month Subscription Plan");
    }
    if (plan === "ANNUAL") {
      setPlanPrice(yearPrice);
      setPriceId(yearlyPriceId);
      setDuration("Year");
      setSubsText("1 Year Subscription Plan");
    }
  }, [plan]);

  useEffect(() => {
    const tokenn = localStorage.getItem("token");

    if (!tokenn) {
      navigate("/");
    }
  }, [navigate]);

  const handleCloseLogout = () => {
    localStorage.clear();
    dispatch(iniateStatesNotes());
    dispatch(iniateStatesCompany());
    dispatch(iniateStatesCompanyDetails());
    dispatch(iniateStatesSnackBar());
    dispatch(iniateStatesUser());

    dispatch(signout(navigate));
  };

  // console.log('planPrice', planPrice)
  // console.log('priceId', priceId)

  return (
    <div className="main_bg" style={{ minHeight: "100vh" }}>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            padding: "45px",
            borderRadius: "20px",
            backgroundColor: "white",
            border: "5px solid white",
          }}
          className="payment-box-bg"
        >
          {" "}
          <div>
            <Button
              onClick={() => {
                handleCloseLogout();
              }}
              style={{ float: "right" }}
            >
              Logout
            </Button>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{ paddingRight: "5%" }}>
                  <img src={Logo} alt="logo" width="70%" />
                  <h2 style={{ color: "black" }}>Subscription Plan</h2>

                  <p style={{ textAlign: "justify" }}>
                    With this {duration === "Month" ? "monthly" : "yearly"}{" "}
                    subscription, you can take advantage of our features and
                    support to make progress and stay on track with your
                    financial, personal, or professional objectives. Whether you
                    need access to exclusive content, personalized guidance, or
                    advanced tools, our monthly subscription plan is a flexible
                    and convenient way to achieve your goals and stay engaged
                    with our community.
                  </p>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    price={planPrice}
                    priceId={priceId}
                    duration={duration}
                    subsText={subsText}
                  />
                </Elements>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
