import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { GRAPH_TOOLTIP_SETTINGS } from "../../utils/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function MisMatchedChart({ misMatched, range }) {
  const options = {
    responsive: true,
    display: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
      tooltip: {
        enabled: true,
        titleFont: {
          size: GRAPH_TOOLTIP_SETTINGS.titleFont, 
        },
        bodyFont: {
          size: GRAPH_TOOLTIP_SETTINGS.bodyFont, 
        },
        padding: GRAPH_TOOLTIP_SETTINGS.padding, 
      },
    },
  };
  const labels = range;

  const data = {
    labels,
    datasets: [
      {
        label: "Gross Fixed Assets",
        data: misMatched.grossFixedAssets,
        borderColor: "#4caf50",
        backgroundColor: "#80ca83",
      },
      {
        label: "Long Term Debt",
        data: misMatched.longTermDebt,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Retained Earnings",
        data: misMatched.retainedEarnings,
        borderColor: "#0c0f7c",
        backgroundColor: "#4447ba",
      },
    ],
  };
  return (
    <div>
      <Line options={options} data={data} />
    </div>
  );
}
