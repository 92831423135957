import React, { useState } from "react";
import { Layout } from "../../layout";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Grid, Typography, Box, Button, CircularProgress } from "@mui/material";
import { CardWrapper } from "../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SnackBar from "../../components/snackBar";
import { contactUsEmail } from "../../redux/actions/user";
import { clearSnack } from "../../utils/utilFunctions";

const CompanyDetail = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userReducer);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);

  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");

  const {
    openSnackbar,
    snackType: snackType2,
    message: message2,
  } = useSelector((state) => state.snackbarReducer);

  const handleSend = async () => {
    if (!subject || !details) {
      dispatch({
        type: "OPEN_SNACK",
        payload: {
          snackType: "error",
          message: "Subject and Details are required!",
        },
      });
      clearSnack(dispatch);
      return;
    }
    setLoading(true);

    const payload = {
      subject,
      details,
      email: user.email,
      name: user.name,
      id: user._id,
    };

    await dispatch(contactUsEmail(payload));
    setLoading(false);
    setSubject("");
    setDetails("");
  };

  return (
    <React.Fragment>
      <Layout title="Company Detail">
        <Grid container>
          <Grid item xs={12}>
            <CardWrapper>
              <Typography className="hidden_cash_subTitle">
                Contact Us{" "}
              </Typography>
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "grid", justifyContent: "stretch" }}
                >
                  <Box style={{ height: 30, width: "100%" }} />
                  <input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="companydetail_inputs"
                    placeholder="Enter subject here"
                  />
                </Grid>
                <Box style={{ height: 25, width: "100%" }} />
                <Grid item xs={12}>
                  <TextareaAutosize
                    className="contact_us_description"
                    placeholder="Please describe your issue or feedback in detail."
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="login-btn"
                  disabled={loading}
                  onClick={handleSend}
                >
                  Send
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "5%",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Button>
              </Box>
            </CardWrapper>
          </Grid>
        </Grid>
        <SnackBar
          setOpen={setOpenSnackBar}
          open={openSnackbar}
          snackType={snackType2}
          msg={message2}
        />
      </Layout>
    </React.Fragment>
  );
};
export default CompanyDetail;
